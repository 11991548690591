import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { ExpensesApiService } from './expenses-api.service';
import { ExpenseDto } from './expenses.typings';

@Injectable({providedIn: 'root'})
export class ExpensesProvider extends DataProvider<ExpenseDto> {
    constructor(protected api: ExpensesApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
