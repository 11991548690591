import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { OverlayConfig } from '@angular/cdk/overlay';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-route-modal',
    templateUrl: './route-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteModalComponent implements OnInit, OnDestroy {

    @Input() public opened = false;
    @Input() public isFullscreen = true;
    @Input() public closeEscDisabled = false;
    @Input() public useBackDropClose = false;
    @Input() public config: OverlayConfig;
    @Input() public id = 'modal';

    @Output() public closeModal: EventEmitter<null> = new EventEmitter();
    @Output() public afterOpen: EventEmitter<null> = new EventEmitter();
    @Output() public afterClosed: EventEmitter<null> = new EventEmitter();

    constructor(
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private router: Router,
    ) {}

    public ngOnInit(): void {
        this.id && this.initOutletListener();
    }

    private initOutletListener(): void {
        this.toggle(this.activatedRoute.firstChild);

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute.firstChild),
                untilDestroyed(this)
            )
            .subscribe(route => {
                this.toggle(route);
            });
    }

    private toggle(route: ActivatedRoute): void {
        this.opened = route && route.outlet === this.id;
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
    }
}
