import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserAuthMessage, UserLogoutMessage } from 'src/app/core/authentication/auth.typing';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { UserModelBasic } from 'src/app/core/services/users/users.typings';

@UntilDestroy()
@Component({
  selector: 'app-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss']
})
export class ProfilePanelComponent implements OnInit {
  @Input() layout: string = 'row';
  @Output() onMenuClick = new EventEmitter();
  
  public showLogin = false;
  public user: UserModelBasic | null;
  public isOpenPdfExpoft = false;

  constructor(
    private readonly authService: AuthenticationService,
    protected messageBus: MessageBusService,
    public readonly cd: ChangeDetectorRef,
    protected readonly router: Router) {
    this.user = this.authService.currentUser;

    this.messageBus.of<UserAuthMessage>(UserAuthMessage)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cd.detectChanges();
      });

    
    this.messageBus.of<UserLogoutMessage>(UserLogoutMessage)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cd.detectChanges();
      });      
  }
  ngOnInit(): void {
  }

  public get isMobileLayout(): boolean {
    return this.layout === 'column';
  }

  public openMyProfile(): void {
    this.router.navigate([`profiles/my`]);
    this.onMenuClick.emit();
  }

  public exit() {
    this.authService.logout()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cd.detectChanges();
      });
      this.onMenuClick.emit();
  }

  public isAuth() : boolean {
    return this.authService.userIsAuthorized();
  }

  public logout() {
    this.cd.detectChanges();
  }

public exportToPdf() {
this.isOpenPdfExpoft = true;
}

}
