import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { of } from 'rxjs';
import { debounceTime, filter, finalize, startWith, switchMap, tap } from 'rxjs/operators';
import { AnimalsProvider } from 'src/app/core/services/animals/animals.provider';
import { AnimalDto } from 'src/app/core/services/animals/animals.typings';
import { ExpensesProvider } from 'src/app/core/services/expenses/expenses.provider';
import { ExpenseDto } from 'src/app/core/services/expenses/expenses.typings';
import { MedicalRecordsProvider } from 'src/app/core/services/medical-records/medical-records.provider';
import { MedicalRecordDto } from 'src/app/core/services/medical-records/medical-records.typings';
import { ProfitsProvider } from 'src/app/core/services/profits/profits.provider';
import { ProfitDto } from 'src/app/core/services/profits/profits.typings';
import { PopupDirective } from 'src/app/shared/components/popup/popup.directive';

@UntilDestroy()
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  @ViewChild('resultPopup', { read: PopupDirective,  static: false }) resultPopup: PopupDirective;

  public isLoadAnimals = false;
  public isLoadMedRecords = false;
  public isLoadExpenses = false;
  public isLoadProfits = false;

  public searchText: UntypedFormControl = new UntypedFormControl();

  public animalsList: Partial<AnimalDto>[] = [];
  public medicalRecordList: Partial<MedicalRecordDto>[] = [];
  public expensesList: Partial<ExpenseDto>[] = [];
  public profitsList: Partial<ProfitDto>[] = [];

  constructor(
    protected readonly animalsApi: AnimalsProvider,
    protected readonly medicalRecordsProvider: MedicalRecordsProvider,
    private readonly expensesProvider: ExpensesProvider,
    private readonly profitsProvider: ProfitsProvider,
    protected readonly router: Router,
    private readonly cd: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.router.events
    .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
    )
    .subscribe(_ => {
      this.searchText.setValue(null);
    });    

    this.medicalRecordSearchInit();
    this.expensesSearchInit();
    this.profitsSearchInit();

    this.searchText.valueChanges
      .pipe(    
        debounceTime(500),  
        switchMap((text) => {
            this.isLoadAnimals = true;
            this.cd.detectChanges();

            if(text === null || text === '') {
              return of({
                data: []
              });
            }

            return this.animalsApi.getList({
              populate: '*',
              'filters[$or][0][name][$containsi]': text,
              'filters[$or][1][description][$containsi]': text,
            })              
            .pipe(
              finalize(() => {
                this.isLoadAnimals = false;
                this.cd.detectChanges();
              }),
            )
          }
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(list => {
        this.animalsList = list.data;
        this.isLoadAnimals = false;
        this.cd.detectChanges();
      });      

  }

  public openAnimalPage(row) {
    this.resultPopup.close();
    this.router.navigate([`animals/list/${row.id}`]);
  }

  public openMedRecordPage(row) {
    this.resultPopup.close();
    this.router.navigate([`/animals/events/medical-records/medical-records-list/${row.id}`]);
  }

  public openExpensePage(row) {
    this.resultPopup.close();
    this.router.navigate([`/expenses/list/other-expenses/other-expenses-list/${row.id}`]);
  }

  public openProfitPage(row) {
    this.resultPopup.close();
    this.router.navigate([`/profits/list/other-profits/other-profits-list/${row.id}`]);
  }

  protected medicalRecordSearchInit(): void {
    this.searchText.valueChanges
      .pipe(    
        debounceTime(500),  
        switchMap((text) => {
            this.isLoadMedRecords = true;
            this.cd.detectChanges();

            if(text === null || text === '') {
              return of({
                data: []
              });
            }

            return this.medicalRecordsProvider.getList({
              'populate[animal][populate][0]': 'avatar',
              'filters[$or][0][name][$containsi]': text,
              'filters[$or][1][symptoms][$containsi]': text,
              'pagination[page]': `1`,
              'pagination[pageSize]': `10`,
            })              
            .pipe(
              finalize(() => {
                this.isLoadMedRecords = false;
                this.cd.detectChanges();
              }),
            )
          }
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(list => {
        this.medicalRecordList = list.data;
        this.isLoadMedRecords = false;
        this.cd.detectChanges();
      });  

  }

  protected expensesSearchInit(): void {
    this.searchText.valueChanges
      .pipe(    
        debounceTime(500),  
        switchMap((text) => {
            this.isLoadExpenses = true;
            this.cd.detectChanges();

            if(text === null || text === '') {
              return of({
                data: []
              });
            }

            return this.expensesProvider.getList({
              'filters[$or][0][name][$containsi]': text,
              'filters[$or][1][description][$containsi]': text,
              'pagination[page]': `1`,
              'pagination[pageSize]': `10`,
            })              
            .pipe(
              finalize(() => {
                this.isLoadExpenses = false;
                this.cd.detectChanges();
              }),
            )
          }
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(list => {
        this.expensesList = list.data;
        this.isLoadExpenses = false;
        this.cd.detectChanges();
      });  

  }

  protected profitsSearchInit(): void {
    this.searchText.valueChanges
      .pipe(    
        debounceTime(500),  
        switchMap((text) => {
            this.isLoadProfits = true;
            this.cd.detectChanges();

            if(text === null || text === '') {
              return of({
                data: []
              });
            }

            return this.profitsProvider.getList({
              'filters[$or][0][name][$containsi]': text,
              'filters[$or][1][description][$containsi]': text,
              'pagination[page]': `1`,
              'pagination[pageSize]': `10`,
            })              
            .pipe(
              finalize(() => {
                this.isLoadProfits = false;
                this.cd.detectChanges();
              }),
            )
          }
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(list => {
        this.profitsList = list.data;
        this.isLoadProfits = false;
        this.cd.detectChanges();
      });  

  }

}
