<app-modal [opened]="opened" [defaultWidth]="450" [useBackDropClose]="false">
    <app-modal-container class="modal-container" ngClass.xs="mobile" [showHeader]="false">
        <div class="modal__header" fxLayout="row" fxLayoutAlign="center center"> {{ 'app.pwa.system-updated' | translate }} </div>
        <div #messageBody class="message__container">
            {{ 'app.pwa.using-new-func' | translate }}
            <p></p>
            <p>{{ 'app.pwa.info' | translate }}</p>            
        </div>
        <div fxLayout="row">
            <button class="close-btn" mat-button (click)="close()">
                {{ 'app.global.buttons.cancel' | translate }}
            </button>  
            <button class="update-btn" mat-raised-button (click)="update()">
                {{ 'app.global.buttons.update' | translate }}
            </button>            
        </div>
    </app-modal-container>
</app-modal>