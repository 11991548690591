<div class="notification__list">
    <div *ngFor="let notification of notifications" class="notification__item">
        <div class="date">{{ notification.date | translate }}</div>
        <div class="notification__name" routerLink="/notifications/notifications-list/{{notification.id}}" (click)="close.emit()">
            {{ notification.name }}
        </div>
        <div fxLayout="row wrap">
            <div *ngFor="let animal of notification.animals">
                <div class="animal-name__container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div *ngIf="animal.avatar">
                        <img [attr.src]="animal.avatar" class="animal-item__image"/>
                    </div>
                    <div class="animal-name">
                        {{ animal.name }}
                    </div>
                    <span> #{{ animal.regNumber }}</span>               
                </div>
            </div>            
        </div>
        <div>
            {{ notification.smallDescription }}
        </div>          
    </div>
</div>