import { Pipe, PipeTransform } from '@angular/core';
import { isNotEmpty } from 'src/app/core/helpers/is-not-empty';

@Pipe({
  name: 'dateLocalize'
})
export class DateLocalizePipe implements PipeTransform {

  constructor() { }

  transform(value: any): string {
    if(!isNotEmpty(value)) {
      return '_';
    }

    if (typeof value === 'string') {
      try {
        return new Date(value).toLocaleDateString();
      } catch (error) {
        return value;
      }
    } else {
      return new Date(value).toLocaleDateString();
    }
  }
}
