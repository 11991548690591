import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SvgImageComponent } from './svg-image.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        SvgImageComponent,
        SafeHtmlPipe
    ],
    exports: [SvgImageComponent]
})
export class SvgImageModule {

}
