export interface ExpenseDto {
    id: number;
    name: string;
    description: string;
    price: number;
    date: string;
    type: ExpenseTypes

}

export enum ExpenseTypes {
    food = 'food',
    equipment = 'equipment',
    medical = 'medical',
    other = 'other'
}