
export interface AnimalDto {
    id: number;
    name: string;
    avatar: ImageDto;
    birthdate: string;
    sex: Sex;
    weight: number;
    growth: number;
    length: number;
    regNumber: number;
    description: string;
    milkYield: number;
    status: AnimalState;
    father: AnimalDto;
    mother: AnimalDto;
    global_status: AnimalStatus;
    photos: any;
    public_animal_card_id: number;
    breed: string;
    breed_purity: number;
}

export enum AnimalStatus {  
    in_herd = 'in_herd',
    from_another_farm = 'from_another_farm',
    sales = 'sales',
    killed = 'killed',
    perished = 'perished'
}

export const animalStatusDict = [
    {
        id: 'in_herd',
        value: 'app.animal.global-statuses.in_herd'
    },
    {
        id: 'from_another_farm',
        value: 'app.animal.global-statuses.from_another_farm'
    },
    {
        id: 'sales',
        value: 'app.animal.global-statuses.sales'
    },
    {
        id: 'killed',
        value: 'app.animal.global-statuses.killed'
    },
    {
        id: 'perished',
        value: 'app.animal.global-statuses.perished'
    },
];

export enum GlobalAnimalStateMap {
    in_herd,
    from_another_farm,
    sales,
    killed,
    perished
}

export enum Sex {
    male = 'male',
    female = 'female',
}

export enum AnimalState {  
    healthy = 'healthy',
    satisfactory = 'satisfactory',
    needObservation = 'needObservation', 
    sick = 'sick',
}

export enum AnimalStateMap {
    healthy,
    satisfactory,
    needObservation,
    sick,
}

export const animalStateDict = [
    {
        id: 'healthy',
        value: 'app.animal.statuses.healthy'
    },
    {
        id: 'satisfactory',
        value: 'app.animal.statuses.satisfactory'
    },
    {
        id: 'needObservation',
        value: 'app.animal.statuses.needObservation'
    },
    {
        id: 'sick',
        value: 'app.animal.statuses.sick'
    },
];

export interface ImageDto {
    data: {
        id: number;
        attributes: {
            url: string;            
        }
    };
}