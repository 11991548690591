<mat-icon
    #appPopup 
    appPopup
    [appPopupTemplate]="notificationsList"
    class="icon">notifications_none</mat-icon> 
<div class="count">{{ countInfo }}</div>

<ng-template #notificationsList>
    <app-popup-container>
      <app-notifications-list [notifications]="notifications" (close)="closePopup()"></app-notifications-list>
    </app-popup-container>        
</ng-template>

