import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-pwa-update-notification',
  templateUrl: './pwa-update-notification.component.html',
  styleUrls: ['./pwa-update-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwaUpdateNotificationComponent implements OnInit {
  public opened = false;

  constructor(
    private readonly updates: SwUpdate,
    protected readonly cd: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {

    this.updates.versionUpdates
      .pipe(untilDestroyed(this))
      .subscribe(event => {   
        switch (event.type) {
          case 'VERSION_DETECTED':
            break;
          case 'VERSION_READY':
            this.opened = true;
            this.cd.detectChanges();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            break;
        }   

      });
  }

  update() {
    this.opened = false;
    this.updates.activateUpdate().then(() => document.location.reload());
    this.cd.detectChanges();
  }

  close() {
    this.opened = false;
    this.cd.detectChanges();
  }

  ngOnDestroy() {

  }

}
