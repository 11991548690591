import { ChangeDetectorRef, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthenticationService } from "../authentication/authentication.service";
import { MessageBusService } from "../message-bus/message-bus-service";

import { Location } from '@angular/common';

@UntilDestroy()
@Injectable()
export abstract class BasePage {
  public isLoad = false;

  constructor(        
        protected readonly router: Router,
        protected readonly authService: AuthenticationService,
        protected readonly messageBus: MessageBusService,
        protected location: Location,
        protected readonly cd: ChangeDetectorRef,
    ) { }

  public get isAuth(): boolean {
      return this.authService.userIsAuthorized();
  }

  public showSpinner() {
    this.isLoad = true;
  }

  public hideSpinner() {
    this.isLoad = false;
  }
  
  public goBack(): void {
    this.location.back();
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


}
