import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { isRequiredField } from 'src/app/shared/validators/validators';
import { ShowInputsErrorMessage } from '../inputs.typings';

@UntilDestroy()
@Component({
  selector: 'app-input',
  templateUrl: `./input.component.html`,
  styleUrls: [`./input.component.scss`],
})
export class InputComponent implements OnInit, OnChanges {
  @Input() vm: AbstractControl;
  @Input() nameControl: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled = false;
  @Input() objectType = 'text';
  @Input() validMessage: string = '';
  @Input() hint: string = '';
  @Input() colorHint: string = '';
  @Input() integerOnly = false;

  @Input() showErrorAfterSubmit = false;

  public showErrors: boolean = false;

  public showPassword: boolean = false;

  constructor(
    protected readonly messageBus: MessageBusService,
    private readonly cd: ChangeDetectorRef
  ) { }

  public get needShowErrors(): boolean {
    return !this.showErrorAfterSubmit || this.showErrors;
  }

  public get viewModel(): UntypedFormControl {
    return this.vm as UntypedFormControl;
  }

  public get inputPlaceholder(): string {
      return this.vm && this.vm.enabled ? this.placeholder : '';
  }

  public get autocompleteName(): string {
    return `new-${this.nameControl}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && changes.disabled.currentValue != null) {
      if (changes.disabled.currentValue) {
        this.vm.disable();
      } else {
        this.vm.enable();
      }
    }
  }

  ngOnInit() {
    this.messageBus.of<ShowInputsErrorMessage>(ShowInputsErrorMessage)      
    .pipe(
      untilDestroyed(this),
    )
    .subscribe((message: ShowInputsErrorMessage) => {
      this.showErrors = true;
      this.cd.detectChanges();
      setTimeout(() => {
        this.showErrors = false;
        this.cd.detectChanges();
      }, message.time);
    });
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public isRequired(): boolean {
    return isRequiredField(this.vm);
  }

  public keyPressNumbers(event: Event) {
    if (this.integerOnly) {
      const keyboardEvent = event as KeyboardEvent;
      // only numbers 0-9
      if (!(keyboardEvent.key in ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])) {
        event.preventDefault();
      }
    }
  }
}
