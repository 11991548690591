<app-modal
  [opened]="show"
  [config]="{ width: 500, height: 600, minWidth: 300 }"
  [useBackDropClose]="false"
>
  <app-modal-container fxLayout="column" [showHeader]="false" (closeModal)="closeModal()">
    <h1 class="title">{{ 'app.global.export' | translate }}</h1>
    <form class="form">
      <app-daterange [vm]="getControl('dateRange')" placeholder="app.pdf-export.period">
      </app-daterange>
      <app-checkbox class="population" [vm]="getControl('population')">
        {{ 'app.main-animals.population' | translate }}
      </app-checkbox>
      <div class="find-details">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'app.pdf-export.costs-incomes' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list #costs (selectionChange)="selectedCostChange($event)">
            <mat-list-option
              class="animals__item"
              *ngFor="let item of costList; let i = index"
              [value]="item.id"
              selected="true"
            >
              <div
                fxFlex="100"
                class="animal-item"
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="start center"
              >
                {{ item.name | translate }}
              </div>
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>

        <mat-expansion-panel class="population">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'app.main-animals.inspections' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list
            #costs
            (selectionChange)="selectedJournalChange($event)"
            class="animals__list"
          >
            <mat-list-option
              class="animals__item"
              *ngFor="let item of journalList; let i = index"
              [value]="item.id"
              selected="true"
            >
              <div
                fxFlex="100"
                class="animal-item"
                fxLayout="row"
                fxLayoutGap="10px"
                fxLayoutAlign="start center"
              >
                {{ item.name | translate }}
              </div>
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
      </div>
      <div class="action-panel">
        <button
          type="button"
          mat-raised-button
          color="primary"
          class="apply-btn"
          (click)="exportData()"
        >
          {{ 'app.pdf-export.export' | translate }}
        </button>
        <button type="button" mat-stroked-button class="cancel-btn" (click)="closeModal()">
          {{ 'app.global.buttons.cancel' | translate }}
        </button>
      </div>
      <!-- <tn-spinner *ngIf="isLoad"></tn-spinner>             -->
    </form>
  </app-modal-container>
</app-modal>
