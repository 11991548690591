import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";

export class BaseViewModel extends UntypedFormGroup {
    private isReadOnly = false;

    public get readOnly(): boolean {
        return this.isReadOnly;
    }

    constructor(
        controls: {
            [key: string]: AbstractControl;
        },
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            ...controls,
            id: controls.id || new UntypedFormControl(null, []),
            version: controls.version || new UntypedFormControl(null, [])
        }, validatorOrOpts);
    }

    public readOnlyMode(): void {
        this.isReadOnly = true;
        this.disable();      
    }

    public enableFields(fieldNames: string[] = []): void {
        fieldNames.forEach(field => {
            const control = this.get(field);
            control?.enable();
        });
    }

    public disableFields(fieldNames: string[] = []): void {
        fieldNames.forEach(field => {
            const control = this.get(field);
            control?.disable();
        });
    }

    public getChildViewModel(path: Array<string | number> | string): BaseViewModel {
        return this.get(path) as BaseViewModel;
    }
}
