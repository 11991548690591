import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppTranslateManagerService } from '../translate/services/app-translate-manager.service';

@Injectable()
@UntilDestroy()
export class PaginatorTranslate extends MatPaginatorIntl{

    constructor(private readonly translateService: AppTranslateManagerService) {
      super();
        this.translateService.setCurrentLanguage();
        this.getAndInitTranslations();
        this.translateService.onChangeLang
        .pipe(untilDestroyed(this)) 
        .subscribe(data => {
          this.getAndInitTranslations();
        })
    }

    getAndInitTranslations() {
        this.translateService
          .get([
                'paginator.itemsPerPage',
                'paginator.lastPage',
                'paginator.firstPage',
                'paginator.nextPage',
                'paginator.previouPage',
                'paginator.of'
            ])
          .pipe(untilDestroyed(this)) 
          .subscribe((data: any) => {
            this.itemsPerPageLabel = data['paginator.itemsPerPage'];
            this.lastPageLabel = data['paginator.lastPage'];
            this.firstPageLabel = data['paginator.firstPage'];
            this.previousPageLabel = data['paginator.previouPage'];
            this.nextPageLabel = data['paginator.nextPage'];
            this.getRangeLabel = this.getCustomRangeLabel;
            this.changes.next();
          });
    }

    getCustomRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
      ): string => {
        const of = this.translateService.translator.instant('paginator.of');

        if (length === 0 || pageSize === 0) {
          return `0 ${of} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${ of } ${length}`;
      };
}