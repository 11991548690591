import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { createValidatorsArray } from 'src/app/shared/validators/validators.mapper';

@UntilDestroy()
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  @Input() show: boolean = false;
  @Output() public close = new EventEmitter();
  
  public isLoad = false;
  public sendCodeMode = true;

  public identity = this.fb.group({
    login: ['', createValidatorsArray(['required', 'email'])],
    password: ['', createValidatorsArray(['required'])]
  });

  err = '';

  constructor(
    private fb: UntypedFormBuilder,
    private authentificationService: AuthenticationService,
    private router: Router,
    private readonly cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes?.show?.currentValue) {
      this.identity.reset();
      this.sendCodeMode = true;
      this.err = '';
      this.hideSpinner();    
    }
  }

  public cancel(): void {
    this.close.emit(); 
  }

  public login() {
    if(this.identity.valid) {
      this.showSpinner();
      
      const login = this.identity.get('login').value.trim();
      const password = this.identity.get('password').value.trim();

      this.authentificationService.login(login, password)
        .pipe(untilDestroyed(this))
        .subscribe(() => {

            // const prevUrl = this.router.parseUrl(this.router.url).queryParams.returnUrl || '';
            this.hideSpinner();
            this.close.emit(); 
            this.cd.detectChanges();
            // this.router.navigate([prevUrl]);
          },
          (error: any) => {
            switch (error.status) {
              case 400:
              case 401:
                this.err = 'The code is invalid or expired';
                break;                
              default:
                  this.err = 'app.login-page.internal-error';
                  break;
            }
            this.hideSpinner();
            this.cd.detectChanges();
          });      
    }
  }

  public sendCode(): void {
    this.showSpinner();
    
    const login = this.identity.get('login').value.trim();

    /*this.authentificationService.sendCode(login)
    .pipe(untilDestroyed(this))
    .subscribe(() => {
        this.hideSpinner();
        this.sendCodeMode = false;
        this.cd.detectChanges();
      },
      (error: any) => {
        this.err = error.status === 400 ? 'Access denied' : 'app.login-page.internal-error';
        this.hideSpinner();
        this.cd.detectChanges();
      });*/

  }

  protected showSpinner() {
    this.isLoad = true;
  }

  protected hideSpinner() {
    this.isLoad = false;
  }


}
