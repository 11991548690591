
export interface Identity {
    identifier: string;
    password: string;
}

export interface UserModelBasic {
    id: number;
    username: string;
    email: string;
    password?: string;
    confirmed: boolean;
    blocked: boolean;
    farm_id: number;
    role?: any;
    createdAt?: string;
}

export interface UserProfileDto {
    id: number;
    username: string;
    email: string;
    date_created: string;
    image: string;
    num_crowd_contributions: number;
    num_public_filters: number;
    num_projects_contributed: number;
}

export interface UserRoleDto {
    key: string;
    value: string;
}

export interface UserModel extends UserModelBasic {
    code: string;
}

export class UserUpdatedMessage {
    public static channel = 'UserUpdatedMessage';
    constructor() { }
}
