export const checkDate = (value: string | Date) => {
  if (value && (value as Date).getDay) {
    value = (value as Date).toISOString();
  }
  if (!checkForCorrectDate(value)) {
    return value;
  }
  value = (value as string).slice(0, -5);
  const date = new Date(value);
  return date.toLocaleDateString('ru-RU');
};

export const checkForCorrectDate = (value: string | Date): boolean => {
  if (value && (value as Date).getDay) {
    value = (value as Date).toISOString();
  }
  return (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}/).test(value as string);
};

export const currentDate = () => {
  const date = new Date(Date.now());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const getOnlyDate = (res: any): Date => {
  if (res instanceof Date) {
    const newDate = new Date(+res);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }
  if (!checkForCorrectDate(res)) {
    throw Error;
  }
  const date = new Date(res);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};


export default {
  checkDate,
  checkForCorrectDate,
  currentDate,
  getOnlyDate,
}