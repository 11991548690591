import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Chart, registerables } from 'chart.js';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './core/authentication/authentication.service';
import { SettingsProvider } from './core/services/settings/settings.provider';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    protected readonly authService: AuthenticationService,
    protected readonly settingsApi: SettingsProvider,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    ) { 
      Chart.register(...registerables);    
    }

  ngOnInit() {
    //this.settingsApi.get({}).subscribe();
    this.router.events
    .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
    )
    .subscribe(_ => {
      this.logPageVisit(window, window.location.href);
    });
    
    setTimeout(() => {
      this.authService.restoreSession();      
    }, 3000);    
  }

  protected logPageVisit(global: any, url): void {
    global.ym && global.ym(91345479, 'hit', url);
  }

}
