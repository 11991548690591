import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        A11yModule,
        TranslateModule,
        MatIconModule
    ],
    declarations: [
        ModalComponent,
        ModalContainerComponent
    ],
    exports: [
        ModalComponent,
        ModalContainerComponent
    ],
})
export class ModalModule {
}
