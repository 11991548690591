import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeleteModalService } from './delete-modal.service';

@UntilDestroy()
@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteModalComponent implements OnInit, OnDestroy {

    constructor(public deleteModalService: DeleteModalService,
                private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.deleteModalService.changeField
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
    }
}
