<div class="container">
    <header *ngIf="showHeader" class="header">
        <div class="title">{{ headerTitle | translate }} <ng-content select="[right_title]"></ng-content> </div>
        <mat-icon *ngIf="showCloseButton"
            class="close-button"
            (click)="closeModal.emit()">
            close</mat-icon>
    </header>
    <div class="sub-container">
        <ng-content></ng-content>
    </div>
</div>
