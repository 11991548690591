import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[appPopup]',
    exportAs: 'appPopup',
})
export class PopupDirective implements OnChanges, OnDestroy {

    @Input() public appPopupTemplate: TemplateRef<HTMLDivElement>;
    @Input() public appPopupPositionX: 'start' | 'center' | 'end' = 'start';
    @Input() public appPopupPositionY: 'top' | 'center' | 'bottom' = 'bottom';
    @Input() public appPopupOverlayX: 'start' | 'center' | 'end' = 'start';
    @Input() public appPopupOverlayY: 'top' | 'center' | 'bottom' = 'top';
    @Input() public appPopupOffsetX: number = 0;
    @Input() public appPopupOffsetY: number = 0;
    @Input() public appPopupActiveClass: string = 'is-active-popup';
    @Input() public appPopupHasBackdrop: boolean = true;
    @Input() public appPopupDisabled: boolean = false;

    @Output() public popupClose: EventEmitter<null> = new EventEmitter();

    private overlayRef: OverlayRef;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
        private cd: ChangeDetectorRef
    ) { 
    }

    @HostListener('click') public onClick(): void {
        if (this.appPopupDisabled || this.overlayRef) {
            return;
        }

        this.overlayRef = this.overlay.create({
            hasBackdrop: this.appPopupHasBackdrop,
            backdropClass: 'mat-overlay-transparent-backdrop',
            positionStrategy: this.getPositionStrategy(),
            scrollStrategy: this.overlay.scrollStrategies.block()
        });

        const template = new TemplatePortal(this.appPopupTemplate, this.viewContainerRef);
        this.overlayRef.attach(template);
        this.overlayRef.backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.close());

        this.appPopupActiveClass && this.renderer.addClass(this.elementRef.nativeElement, this.appPopupActiveClass);
        this.overlayRef.updatePosition();

        this.cd.detectChanges();
       
    }

    public close(): void {
        this.appPopupActiveClass && this.renderer.removeClass(this.elementRef.nativeElement, this.appPopupActiveClass);
        this.popupClose.emit();
        this.overlayRef && this.overlayRef.dispose();
        this.overlayRef = null;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.mtPopupDisabled && changes.mtPopupDisabled.currentValue) {
            this.close();
        }
    }

    private getPositionStrategy(): PositionStrategy {
        
        return this.overlay.position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                overlayX: this.appPopupOverlayX,
                overlayY: this.appPopupOverlayY,
                originX: this.appPopupPositionX,
                originY: this.appPopupPositionY,
                offsetX: this.appPopupOffsetX,
                offsetY: this.appPopupOffsetY,
            }])
            .withFlexibleDimensions(false)
            .withPush(true);
    }

    ngOnDestroy() {
        this.close();
    }
}
