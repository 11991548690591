import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { InseminationsApiService } from './inseminations-api.service';
import { InseminationDto } from './inseminations.typings';

@Injectable({providedIn: 'root'})
export class InseminationsProvider extends DataProvider<InseminationDto> {
    constructor(protected api: InseminationsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
