<div fxLayout="column" fxLayoutAlign="center center">
    <img fxFlex="150px" class="logo" src="/assets/icons/empty-goat.png" alt="">
    <h1 class="title">
        Политика конфиденциальности
    </h1>
</div>

<div class="offer__container">
    <div>В соответствии с Законом Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных»</div>

    <div class="section">
        В соответствии с ч. 2 п. 5 ст. 5, ст. 10 - 15 Закона Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных» разъясняем, что Вы как субъект персональных данных вправе в любое время без объяснения причин отозвать свое согласие на обработку персональных данных посредством подачи заявления ИП «Осипович Александр Витальевич»
    </div>

    <div class="section">
        <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
        <p>1.1. Настоящая Политика конфиденциальности GoatFarms в отношении обработки персональных данных (далее – «Политика») разработана во исполнение требований абз. 3 п. 3 ст. 17 Закона Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных» (далее - Закон о персональных данных) в целях обеспечения защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личной и семейной тайны.</p>
        <p>1.2. Политика действует в отношении всех персональных данных, которые обрабатывает сервис GoatFarms</p>
        <p>ИП Осипович Александр Витальевич (далее - «Оператор») является лицом, ответственным за сбор, хранение, обработку и распространение личных данных пользователей Сайта.</p>
        <p>1.3. Политика распространяется на отношения в области обработки персональных данных, возникшие в рамках использования сервиса GoatFarms как до, так и после утверждения Политики.</p>
        <p>1.4. Во исполнение требований п. 4 ст. 17 Закона о персональных данных Политика публикуется в свободном доступе в информационно-телекоммуникационной сети Интернет на сайте сервиса GoatFarms - http://www.goat-farms.by/</p>
        <p>1.5. В настоящей Политике используются следующие термины:</p>
        <p>- «Персональные данные» - любая информация, относящаяся к идентифицированному физическому лицу или физическому лицу, которое может быть идентифицировано (абз. 9 ст.1 Закона о персональных данных);</p>
        <p>- «Субъект персональных данных» – физическое лицо, в отношении которого осуществляется обработка персональных данных (абз. 13 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Обработка персональных данных» - любое действие или совокупность действий, совершаемые с персональными данными, включая сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, распространение, предоставление, удаление персональных данных (абз. 9 ст.1 Закона о персональных данных);</p>
        <p>- «Распространение персональных данных» - действия, направленные на ознакомление с персональными данными неопределенного круга лиц (абз. 11 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Предоставление персональных данных» - действия, направленные на ознакомление с персональными данными определенных лица или круга лиц (абз. 10 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Блокирование персональных данных» - прекращение доступа к персональным данным без их удаления (абз. 3 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Удаление Персональных данных» - действия, в результате которых становится невозможным восстановить персональные данные в информационных ресурсах (системах), содержащих персональные данные, и (или) в результате которых уничтожаются материальные носители персональных данных (абз. 15 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Обезличивание Персональных данных» - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных (абз. 5 ст. 1 Закона о персональных данных от 07.05.2021 № 99-З);</p>
        <p>- «Автоматизированная обработка персональных данных» - обработка персональных данных с помощью средств вычислительной техники;</p>
        <p>- «Информационная система персональных данных» - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
        <p>Сайт - интернет ресурс Оператора с доменным имением http://www.goat-farms.by.</p>
        <p>1.6. Основные права и обязанности Оператора.</p>
        <p>1.6.1. Оператор имеет право:</p>
        <p>1) самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено законодательством;</p>
        <p>2) в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных.</p>
        <p>1.6.2. Оператор обязан:</p>
        <p>1) организовывать обработку персональных данных в соответствии с требованиями Закона о персональных данных;</p>
        <p>2) отвечать на обращения и запросы субъектов персональных данных в соответствии с требованиями Закона о персональных данных;</p>
        <p>3) сообщать в уполномоченный орган по защите прав субъектов персональных данных о нарушениях систем защиты персональных данных незамедлительно, но не позднее трех рабочих дней после того, как Оператору стало известно о таких нарушениях;</p>
        <p>4) исполнять требования уполномоченного органа по защите прав субъектов персональных данных об устранении нарушений законодательства о персональных данных.</p>
        <p>1.7. Субъект персональных данных имеет право:</p>
        <p>1) получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных законодательством. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;</p>
        <p>2) требовать от Оператора уточнения его персональных данных в случае, если персональные данные являются неполными, устаревшими, неточными;</p>
        <p>3) получать информацию о предоставлении его персональных данных третьим лицам, за исключением случаев, предусмотренных законодательством Республики Беларусь;</p>
        <p>4) в любое время без объяснения причин отозвать свое согласие на обработку персональных данных;</p>
        <p>5) требовать от Оператора блокирования или удаления его персональных данных, если они незаконно получены или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
        <p>6) обжаловать действия (бездействие) и решения Оператора, нарушающие его права при обработке персональных данных, в уполномоченный орган по защите прав субъектов персональных данных в порядке, установленном законодательством об обращениях граждан и юридических лиц.</p>
        <p>1.8. Контроль за исполнением требований Политики осуществляется лицом, ответственным за организацию обработки персональных данных у Оператора.</p>
        <p>1.9. Ответственность за нарушение требований законодательства Республики Беларусь и нормативных актов Оператора в сфере обработки и защиты персональных данных определяется в соответствии с законодательством Республики Беларусь.</p>
    </div>

    <div class="section">
        <h2>2. ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
        <p>2.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
        <p>2.3. Обработка Оператором персональных данных осуществляется в следующих целях:</p>
        <p>- обеспечение соблюдения законодательства Республики Беларусь;</p>
        <p>- осуществление своей деятельности в соответствии с уставом Оператора, в том числе оказание услуг с использованием Сайта Оператора и иных интернет ресурсов, программных мобильных приложений, информационных систем, принадлежащих Оператору;</p>
        <p>- для улучшения и совершенствования сервисов Оператора</p> 
        <p>- пресечение попыток злоупотребления сервисами Оператора</p>
        <p>- разработка продукта и анализ тенденций в сегменте</p>
    </div>
    <div class="section">
        <h2>3. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
        <p>3.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе:</p>
        <p>- Конституция Республики Беларусь;</p>
        <p>- Гражданский кодекс Республики Беларусь;</p>
        <p>- Закон о персональных данных;</p>
        <p>- иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.</p>
        <p>3.2. Правовым основанием обработки персональных данных также являются:</p>
        <p>- устав Оператора;</p>
        <p>- договоры, заключаемые между Оператором и субъектами персональных данных;</p>
        <p>- согласие субъектов персональных данных на обработку их персональных данных.</p> 
    </div>
    <div class="section">
        <h2>4. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>4.1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки, предусмотренным в разд. 2 Политики. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.</p>
        <p>4.2. Оператор может обрабатывать перечисленные персональные данные следующих категорий субъектов персональных данных.</p>
        <p>4.2.1. Данные которые пользователи самостоятельно указывают на сайте GoatFarms:</p>
        <p>- фамилия, имя, отчество;</p>
        <p>- пол;</p>
        <p>- гражданство;</p>
        <p>- дата и место рождения;</p>
        <p>- контактные данные;</p>
        <p>- место проживания</p>
        <p>4.2.2. Данные которые автоматичеси собираются сервисами для улучшения качества обслуживания:</p>
        <p>- о географическом местоположении</p>
        <p>- об устройствах, используемых пользователями для посещения Сайта</p>
        <p>- о типе используемого интернет-подключения</p>
        <p>- данные о пользовании сервисами GoatFarms</p>
        <p>- Файлы cookie и прочий локально хранящийся контент. Принимая файлы cookie на Сайте, вы предоставляете нам доступ к информации о том, какие страницы вы просматриваете в рамках сервиса GoatFarms. Эта информация может быть использована для настройки персонализированного контента на Сайте, а также для статистических исследований.</p>

    </div>
    <div class="section">
        <h2>5. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>5.1. Обработка персональных данных осуществляется Оператором в соответствии с требованиями законодательства Республики Беларусь.</p>
        <p>5.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных на обработку их персональных данных, а также без такового согласия в случаях, предусмотренных законодательством Республики Беларусь.</p>
        <p>5.3. Оператор осуществляет как автоматизированную, так и неавтоматизированную обработку персональных данных.</p>
        <p>5.4. Обработка персональных данных осуществляется путем:</p>
        <p>- получения персональных данных в устной и письменной форме непосредственно от субъектов персональных данных;</p>
        <p>- получения персональных данных из общедоступных источников;</p>
        <p>- получения персональных данных посредством Сайта и иных интернет ресурсов, программных мобильных приложений, информационных систем, принадлежащих Оператору;</p>
        <p>- внесения персональных данных в журналы, реестры и информационные системы Оператора;</p>
        <p>- использования иных способов обработки персональных данных.</p>
        <p>5.6. Не допускается раскрытие третьим лицам и распространение персональных данных без согласия субъекта персональных данных, если иное не предусмотрено законодательством. Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных.</p>
        <p>5.7. Передача персональных данных органам дознания и следствия, и другие органы исполнительной власти и организации осуществляется в соответствии с требованиями законодательства Республики Беларусь.</p>
        <p>5.8. Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других несанкционированных действий, в том числе:</p>
        <p>- определяет угрозы безопасности персональных данных при их обработке;</p>
        <p>- принимает локальные нормативные акты и иные документы, регулирующие отношения в сфере обработки и защиты персональных данных;</p>
        <p>- создает необходимые условия для работы с персональными данными;</p>
        <p>- организует работу с информационными системами, в которых обрабатываются персональные данные;</p>
        <p>- хранит персональные данные в условиях, при которых обеспечивается их сохранность и исключается неправомерный доступ к ним;</p>
        <p>5.9. Оператор осуществляет хранение персональных данных не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законодательством Республики Беларусь, договором.</p>
    </div>
   
    <div class="section">
        <h2>6. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>6.1. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в п. 1 и 4 ст. 11, п. 1 ст. 12 Закона о персональных данных, предоставляются Оператором субъекту персональных данных при получении заявления субъекта персональных данных.</p>
        <p>В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.</p>
        <p>Заявление должно содержать:</p>
        <p>- фамилию, собственное имя, отчество (если таковое имеется) субъекта персональных данных, адрес его места жительства (места пребывания);</p>
        <p>- дату рождения субъекта персональных данных;</p>
        <p>- идентификационный номер субъекта персональных данных, при отсутствии такого номера - номер документа, удостоверяющего личность субъекта персональных данных, в случаях, если эта информация указывалась субъектом персональных данных при даче своего согласия оператору или обработка персональных данных осуществляется без согласия субъекта персональных данных;</p>
        <p>- изложение сути требований субъекта персональных данных;</p>
        <p>- личную подпись либо электронную цифровую подпись субъекта персональных данных.</p>
        <p>Заявление может быть направлено в письменной форме, в форме электронного документа, подписанного электронной цифровой подписью в соответствии с законодательством Республики Беларусь.</p>
        <p>Если в заявлении субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.</p>
        <p>Субъекту персональных данных может быть отказано в предоставлении информации в соответствии с п. 3 ст. 11 Закона о персональных данных.</p>
        <p>6.2. В случае выявления неточных персональных данных при обращении субъекта персональных данных либо по его заявлению или по запросу уполномоченного органа по защите прав субъектов персональных данных Оператор осуществляет блокирование персональных данных, относящихся к этому субъекту персональных данных, с момента получения указанного заявления или запроса на период проверки.</p>
        <p>В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных субъектом персональных данных либо уполномоченным органом по защите прав субъектов персональных данных, или иных необходимых документов уточняет (изменяет) персональные данные в течение 15 дней со дня представления таких сведений и снимает блокирование персональных данных.</p>
        <p>6.3. В случае выявления неправомерной обработки персональных данных при получении заявления субъекта персональных данных либо запроса уполномоченного органа по защите прав субъектов персональных данных Оператор осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения заявления (запроса).</p>
        <p>6.4. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку персональные данные подлежат удалению, если иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных или законодательством.</p>    
    </div>    
</div>




