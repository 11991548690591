<form class="form">
    <div class="content" fxLayout="row" fxLayoutAlign="center start" fxLayout.xs="column" fxFlexFill>   
        <div *ngIf="showRegistration" fxFlex="550px" fxFlex.xs="100" class='login_div' fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img fxFlex="100px" class="logo" src="/assets/icons/empty-goat.png" alt="">
                <h1 class="title">
                    {{ 'app.global.registration' | translate }}
                </h1>
                <p class="notice">
                </p>
            </div>
            <div class="login-form" fxFlex="100">
                <app-input class="field" [vm]="viewModel?.get('username')" label="app.global.login" placeholder="app.global.login"></app-input>
                <app-input class="field" [vm]="viewModel?.get('email')" label="Email"  placeholder="Email"></app-input>
                <app-input 
                    class="field" 
                    [vm]="viewModel?.get('password')"
                    label="app.login.password"  
                    placeholder="app.login.password" 
                    objectType="password">
                </app-input>
                <app-input 
                    class="field"
                    [vm]="viewModel?.get('repeatPassword')"
                    label="app.registration.confirm-password" 
                    placeholder="app.registration.confirm-password" 
                    objectType="password">
                </app-input>
                <div>
                    <mat-button-toggle-group [formControl]="isNewFarm">
                        <mat-button-toggle [value]="true">{{ 'app.registration.new-farm' | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="false">{{ 'app.registration.join-a-farm' | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                    <div *ngIf="isNewFarm.value" class="farm-container">
                        <app-input class="field" [vm]="farmViewModel?.get('name')" label="app.registration.farm-name" placeholder="app.registration.farm-name"></app-input>
                    </div>
                    <div *ngIf="!isNewFarm.value" class="farm-container">
                        <app-farms-autocomplete label="app.registration.farm" placeholder="app.registration.choose-farm" [vm]="viewModel?.get('farm_id')"></app-farms-autocomplete>                        
                    </div>
                </div>
                <div>
                    <div>{{ 'app.registration.registration-title' | translate }} 
                        <a class="link" href="/public-offer" target="_blank">{{ 'app.registration.user-agreement' | translate }}</a> 
                        {{ 'app.registration.agree' | translate }} <a class="link" href="/privacy-policy" target="_blank">{{ 'app.registration.privacy-policy' | translate }}</a></div>
                </div>

                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.registration.register' | translate"
                    (click)="onSubmit()">
                    <div ellipsifyText>
                        {{ 'app.registration.register' | translate }}
                    </div>
                </button>    
            </div>
        </div>
        <div *ngIf="!showRegistration" class="check-email-modal">
            <img class="check-email__logo" src="/assets/images/check-email_logo.svg" alt="">
            <div class="check-email-modal__title">{{ 'app.registration.thanks' | translate }}</div>
            <div class="check-email-modal__body">
                <p>{{ 'app.registration.email-sent' | translate }}</p>
                <p class="check-email-modal__email">{{ viewModel?.get('email').value }}</p>
                <p>{{ 'app.registration.available-link' | translate }}</p>
            </div>
            <div class="check-email-modal__bottom-panel">
                <button
                    mat-stroked-button
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.registration.send-email' | translate"
                    (click)="resendEmail()">
                    <div ellipsifyText>
                        {{ 'app.registration.send-email' | translate }}
                    </div>
                </button>  
            </div>
        </div>
    </div>
    <tn-spinner *ngIf="isLoad"></tn-spinner>
</form>
<app-footer class="footer"></app-footer>