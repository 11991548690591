import { NgModule } from '@angular/core';
import { MessageBusService } from './message-bus-service';


@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [MessageBusService]
})
export class MessageBusModule {

}
