import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { UserUpdatedMessage } from 'src/app/core/services/users/users.typings';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent implements OnInit, OnDestroy {  
  @Input() showMenu = false;

  @Output() public logout = new EventEmitter();

  public user: any;

  public userName: string;
  public department: string;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
    private readonly authentificationService: AuthenticationService,
    protected messageBus: MessageBusService
  ) { }

  ngOnInit() {
    
    this.messageBus.of<UserUpdatedMessage>(UserUpdatedMessage)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadUser();
      });

    this.loadUser();
  }

  ngOnDestroy() {
  }

  public openMyProfile(): void {
    this.router.navigate([`profiles/my`]);
  }

  public exit() {
    this.authentificationService.logout()
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.logout.emit();
    });
  }

  protected loadUser(): void {
    this.authentificationService.getCurrentUser(true)
    .pipe(untilDestroyed(this))
    .subscribe((user: any) => {
      this.user = {
        ...user,
        image: user.image ? `${environment.baseHost}${user.image}` : null
      };
      this.cd.detectChanges();
    });
  }

}
