<ng-template #templateRef>
    <div class="modal-container"
         [@dialogContainer]="dialogState"
         (@dialogContainer.start)="animationStart($event)"
         (@dialogContainer.done)="animationDone($event)"
    >
        <div
            class="modal-content"
            cdkTrapFocus
            [cdkTrapFocusAutoCapture]="true"
        >
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
