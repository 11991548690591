import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, } from '@angular/core';
import { BaseErrorMessage } from '../../common.definitions';
import { ErrorType, getServerError, ServerErrorDto } from '../../server-errors';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { Router } from '@angular/router';
import { Notifications, NotificationService } from 'src/app/shared/components/notification/notification.service';
import { AppTranslateService } from 'src/app/core/translate/services/app-translate.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const INTERNAL_SERVER_ERROR = 'app.shared.errors.internal-server-error';

@UntilDestroy()
@Component({
    selector: 'app-global-error-handler',
    templateUrl: './global-error-handler.component.html',
    styleUrls: ['./global-error-handler.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalErrorHandlerComponent implements OnInit, OnDestroy {

    constructor(private readonly notificationService: NotificationService,
                private readonly appTranslateService: AppTranslateService,
                private readonly messageBus: MessageBusService,
                private readonly router: Router) {
    }

    public ngOnInit(): void {
        this.messageBus.of(BaseErrorMessage)
            .pipe(untilDestroyed(this))
            .subscribe((message: BaseErrorMessage) => this.handleError(message.error));
    }

    protected handleError(errorResponse: ServerErrorDto): void {
        if (!errorResponse) {
            this.notificationService.setText(this.appTranslateService.instant('app.errors.bad-request'), Notifications.Error);
            return;
        }
        const error = getServerError(errorResponse);

        switch (error.type) {
            case ErrorType.VALIDATION:
            case ErrorType.CLIENT_ERROR: {
                if (error.innerErrors.length > 0) {
                    this.notificationService.setText(error.innerErrors.map(e => e.message).join(',\n'), Notifications.Error);
                } else {
                    this.notificationService.setText(this.appTranslateService.instant(error.message) || error.message, Notifications.Error);
                }
                break;
            }
            case ErrorType.INTERNAL_SERVER: {
                this.notificationService.setText(
                  this.appTranslateService.instant(INTERNAL_SERVER_ERROR), Notifications.Error);
                break;
            }
            case ErrorType.AUTH_ERROR: {
                this.router.navigateByUrl(`login`);
                break;
            }
            case ErrorType.ACCESS_DENIED: {
                this.notificationService.setText(this.appTranslateService.instant('fields.errors.access-denied'), Notifications.Error);
                break;
            }
            case ErrorType.NOT_ACCEPTABLE: {
                const message = error && error.innerErrors[0] && error.innerErrors[0].message ?
                  error.innerErrors[0].message :
                  this.appTranslateService.instant(INTERNAL_SERVER_ERROR);
                this.notificationService.setText(message, Notifications.Error);
                break;
            }

            default:
                this.notificationService.setText(this.appTranslateService.instant(INTERNAL_SERVER_ERROR), Notifications.Error);
        }
    }

    ngOnDestroy() {
        // To protect you, we'll throw an error if it doesn't exist.
    }

}
