import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class PwaOfflineService {
    protected isOffline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!navigator.onLine);

    public get isOffline$(): Observable<boolean> {
        return this.isOffline.asObservable();
    } 

    constructor(
        private readonly http: HttpClient,
    ) {
        window.ononline = () => this.isOffline.next(!navigator.onLine);
        window.onoffline = () => this.isOffline.next(!navigator.onLine);
    }

}