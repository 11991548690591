import { BaseModel } from "../api-client/api-client.typings";

interface ItemWithKey {
    key: number | string;
}

interface ItemWithValue {
    value: number | string;
}

export function trackByIdFn<T extends  BaseModel>(index: number, item: T): number | string {
    return item && item.id || index;
}

export function trackByKeyFn(index: number, item: ItemWithKey): number | string {
    return item && item.key || index;
}

export function trackByStringFn(index: number, item: string): string {
    return item || index.toString();
}

export function trackByNameFn(index: number, item: any): number | string {
    return item && item.name || index;
}

export function trackByValueFn(index: number, item: ItemWithValue): number | string {
    return item && item.value || index;
}
