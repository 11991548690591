import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoComponent } from './user-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserInfoComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        TranslateModule
    ],
  exports: [UserInfoComponent],
})
export class UserInfoModule { }
