import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationDto } from 'src/app/core/services/notifications/notifications.typings';

export class UpdateNotificationsMessage {
  public static channel = 'UpdateNotificationsMessage';
  constructor() { }
}


@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit {
  @Input() public notifications: NotificationDto[] = [];
  @Output() public close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
