<form class="form">
    <div class="content" fxLayout="row" fxLayoutAlign="center start" fxLayout.xs="column" fxFlexFill>   
        <div *ngIf="showRecoveryForm" fxFlex="550px" fxFlex.xs="100" class='login_div' fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img fxFlex="150px" class="logo" src="/assets/icons/empty-goat.png" alt="">
                <h1 class="title">
                    {{ 'app.forgot-password.title' | translate }}
                </h1>
                <p class="notice">
                    {{ 'app.forgot-password.notice-1' | translate }}
                </p>
            </div>
            <div class="login-form" fxFlex="100">
                <app-input class="login-field" [vm]="vm?.get('email')" placeholder="Email"></app-input>
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.login.login-button' | translate"
                    (click)="onSubmit()">
                    <div ellipsifyText>
                        {{ 'app.global.buttons.send' | translate }}
                    </div>
                </button>    
            </div>
        </div>
        <div *ngIf="!showRecoveryForm" class="check-email-modal">
            <img class="check-email__logo" src="/assets/images/check-email_logo.svg" alt="">
            <div class="check-email-modal__title">{{ 'app.forgot-password.title-2' | translate }}</div>
            <div class="check-email-modal__body">
                <p>{{ 'app.forgot-password.notice-2' | translate }}</p>
                <p class="check-email-modal__email">{{ vm?.get('email').value }}</p>
                <p>{{ 'app.forgot-password.notice-3' | translate }}</p>
            </div>
            <div class="check-email-modal__bottom-panel">
                <button
                    mat-stroked-button
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.forgot-password.resend' | translate"
                    (click)="resendEmail()">
                    <div ellipsifyText>
                        {{ 'app.forgot-password.resend' | translate }}
                    </div>
                </button>  
            </div>
        </div>
    </div>
    <tn-spinner *ngIf="isLoad"></tn-spinner>
</form>
