<div class="main">
    <button class="menu-button" mat-icon-button
            (click)="sidenav?.toggle()">
            <mat-icon>menu</mat-icon>
    </button>
    <div class="separator"></div>
    <img class="logo" src="/assets/icons/empty-goat.png" (click)="toMain()"/>
    <div class="text-logo">Goat<span class="farms">Farms</span></div>

    <div class="right_side"></div>
    <app-profile-panel class="profile-panel"></app-profile-panel>
</div>

