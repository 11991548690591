import { AnimalDto } from "../animals/animals.typings";
import { PreparationDto } from "../medical-preparations/medical-preparations.typings";
import { ReviewDto } from "../reviews/reviews.typings";

export interface MedicalRecordDto {
    id: number;
    name: string;
    symptoms: string;
    description: string;
    animal: AnimalDto;
    reviews: ReviewDto[];
    cost: number;
    date: string;
    status: MedicalRecordStatuses;
    medical_preparations: PreparationDto[],
    version: number;
}

export enum MedicalRecordStatuses {
    active = 'active',
    passive = 'passive',
    close = 'close'
}

export enum MedicalRecordStatusesMap {
    active,
    passive,
    close,
}

export const MedicalRecordStatusesDict = [
    {
        id: 'active',
        value: 'app.medical.statuses.active'
    },
    {
        id: 'passive',
        value: 'app.medical.statuses.passive'
    },
    {
        id: 'close',
        value: 'app.medical.statuses.close'
    }
];