import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({providedIn: 'root'})
export class PdfCreator {

    private font: any;
    private fontBold: any;
    constructor() {
        let pFont = fetch("/assets/fonts/OpenSans/OpenSans-Regular.ttf")
        .then(response => response.blob())
        .then(response => this.getBase64(response))
        .then(response => {
          return (response as string).split(',')[1];
        });
    
      let pFontBold = fetch("/assets/fonts/OpenSans/OpenSans-Bold.ttf")
        .then(response => response.blob())
        .then(response => this.getBase64(response))
        .then(response => {
          return (response as string).split(',')[1];
        });

        Promise.all([pFont, pFontBold])
        .then(([font, fontBold]) => {
            this.font = font;
            this.fontBold = fontBold;
        });
     }

    private getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    public getPdf({orientation}) {
        let pdf = new jsPDF({orientation});
        pdf.addFileToVFS('Font.ttf', this.font);
        pdf.addFileToVFS('FontBold.ttf', this.fontBold);
        pdf.addFont('Font.ttf', 'font', 'normal');
        pdf.addFont('FontBold.ttf', 'font', 'bold');

        pdf.setFont('font');

        return pdf;
    }

    public paintTable(pdf, headers, data) {
        autoTable(pdf, {
            head: headers,
            body: data,
            headStyles: { font: 'font' },
            bodyStyles: { font: 'font' },
          })
    }
}