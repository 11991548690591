import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { AccessDeniedPageComponent } from './modules/auth/access-denied-page/access-denied-page.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { RegistrationPageComponent } from './modules/auth/registration-page/registration-page.component';
import { ResetPasswordPageComponent } from './modules/auth/reset-password-page/reset-password-page.component';
import { HomeComponent } from './modules/home/home.component';
import { MainComponent } from './modules/main/main.component';
import { PrivacyPolicyModule } from './modules/privacy-policy/privacy-policy.module';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy/privacy-policy.component';
import { PublicOfferComponent } from './modules/public-offer/public-offer/public-offer.component';

const childrenRoutes = [
  {
    path: 'animals',
    loadChildren: () => import ('./modules/animals/animals.module').then(m => m.AnimalsModule),
  },
  {
    path: 'expenses',
    loadChildren: () => import ('./modules/expenses/expenses.module').then(m => m.ExpensesModule),
  },
  {
    path: 'profits',
    loadChildren: () => import ('./modules/profits/profits.module').then(m => m.ProfitsModule),
  },
  {
    path: 'profiles',
    loadChildren: () => import ('./modules/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import ('./modules/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
  },
  {
    path: 'production',
    loadChildren: () => import ('./modules/production/production.module').then(m => m.ProductionModule),
  }, 
  {
    path: 'notifications',
    loadChildren: () => import ('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
  }, 
  {
    path: 'access-denied',
    component: AccessDeniedPageComponent
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'animals',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainComponent,
    children: childrenRoutes,
    canActivateChild: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'registration',
    component: RegistrationPageComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordPageComponent
  },
  {
    path: 'public-offer',
    component: PublicOfferComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'public-animals',
    loadChildren: () => import ('./modules/public-animals/public-animals.module').then(m => m.PublicAnimalsModule),
  },
  {
    path: 'articles',
    loadChildren: () => import ('./modules/articles/articles.module').then(m => m.ArticlesModule),
  },     

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
