import { Directive, AfterViewInit, ElementRef, Renderer2, HostListener, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppTranslateManagerService } from 'src/app/core/translate/services/app-translate-manager.service';

@UntilDestroy()
@Directive({
    selector: '[showForLocale]',
})
export class ShowForLocaleDirective implements OnInit {
    @Input() showForLocale: string | string [];

  constructor(
      private translateManager: AppTranslateManagerService,
      private elementRef: ElementRef,
      private renderer: Renderer2
    ) { }

    ngOnInit(): void {
        this.setVisibility(this.translateManager.getCurrentLang());

        this.translateManager.onChangeLang
        .pipe(untilDestroyed(this))
        .subscribe((locale) => {
            this.setVisibility(locale);
        });       
    }

    private setVisibility(locale: string): void {
        const locales = Array.isArray(this.showForLocale) ? this.showForLocale : [this.showForLocale];
        if(locales.indexOf(locale) === -1) {
            this.renderer.addClass(this.elementRef.nativeElement, 'hide-element');
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement, 'hide-element');
        }        
    }

}
