import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum Notifications {
  Success,
  Error,
  Notice
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  show = false;
  type: Notifications;
  text = '';
  changeState = new Subject();

  constructor() {
  }

  setText(text: string, type: Notifications) {
    this.show = true;
    this.text = text;
    this.type = type;
    this.changeState.next(null);
    setTimeout(() => {
      this.show = false;
      this.changeState.next(null);
    }, 7000);
  }
}
