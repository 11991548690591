<div class="user-info-menu-container pointer"
     [class.with-menu]="showMenu"
     [matMenuTriggerFor]="menu">
    <mat-icon *ngIf="!user?.image" class="empty-avatar">account_circle</mat-icon>
    <img *ngIf="user?.image" class="avatar" [src]="user?.image"/>
    <div class="info">
        <div class="userName">
            {{user?.username}}
        </div>
    </div>
    <mat-icon *ngIf="showMenu">keyboard_arrow_down</mat-icon>
</div>

<mat-menu #menu="matMenu" [overlapTrigger]="false"
          backdropClass="user-info-menu">
    <button class="menu-item" mat-menu-item (click)="openMyProfile()">
        <span class="profile">{{ 'app.global.profile' | translate }}</span>
    </button>
    <button class="menu-item" mat-menu-item (click)="exit()">
        <span class="exit">{{ 'app.shared.left-side-menu.exit' | translate }}</span>
    </button>

</mat-menu>

