import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    OnDestroy,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { matDialogAnimations } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [matDialogAnimations.dialogContainer],
})
export class ModalComponent implements OnChanges, OnDestroy {
    @Input() public opened = false;
    @Input() public isFullscreen = false;
    @Input() public closeEscDisabled = false;
    @Input() public useBackDropClose = true;
    @Input() public config: OverlayConfig;
    @Input() public defaultWidth = 600;

    @Output() public closeModal: EventEmitter<null> = new EventEmitter();
    @Output() public afterOpen: EventEmitter<null> = new EventEmitter();
    @Output() public afterClosed: EventEmitter<null> = new EventEmitter();

    @ViewChild('templateRef', { static: true }) public templateRef: TemplateRef<{}>;

    public dialogState: 'void' | 'enter' | 'exit' = 'enter';

    private overlayRef: OverlayRef;
    private defaultHeight: number | string = 'auto';
    

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
    ) {}

    public ngOnChanges(): void {
        if (this.opened && !this.overlayRef) {
            this.open();
            return;
        }
        this.close();
    }


    public open(): void {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        if (this.isFullscreen) {
            this.overlayRef = this.overlay.create({
                height: '100vh',
                width: '100vw',
                minWidth: '100vw',
                scrollStrategy
            });
        } else {
            this.overlayRef = this.overlay.create({
                height: this.defaultHeight,
                width: this.defaultWidth,
                positionStrategy: this.getPositionStrategy(),
                scrollStrategy,
                ...this.config,
                hasBackdrop: true,
            });

            if (this.useBackDropClose) {
                this.overlayRef.backdropClick()
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.close();
                });

                if (!this.closeEscDisabled) {
                    this.overlayRef.keydownEvents()
                        .pipe(
                            filter(event => event.key === 'Escape'),
                            untilDestroyed(this),
                        )
                        .subscribe(() => {
                            this.close();
                        });
                }

            }

        }
        const template = new TemplatePortal(this.templateRef, this.viewContainerRef);
        this.overlayRef.attach(template);
        this.dialogState = 'enter';
        this.afterOpen.emit();
    }

    public animationStart(event: AnimationEvent): void {
        if (event.phaseName === 'start' && event.toState === 'exit') {
            this.overlayRef.detachBackdrop();
        }
    }

    public animationDone(event: AnimationEvent): void {
        if (event.phaseName === 'done' && event.toState === 'exit') {
            this.overlayRef && this.overlayRef.dispose();
            this.overlayRef = null;
            this.dialogState = 'void';
            this.afterClosed.emit();
        }
    }

    public close(): void {
        if (this.overlayRef) {
            this.dialogState = 'exit';
            this.closeModal.emit();
            this.overlayRef.detach();
            this.overlayRef = null;
        }

    }

    private getPositionStrategy(): PositionStrategy {
        return this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
    }

    ngOnDestroy() {
        this.close();
    }
}
