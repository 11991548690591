import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { AnimalDto } from './animals.typings';

@Injectable({providedIn: 'root'})
export class AnimalsApiService extends ApiClient<AnimalDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}animals`);
     }

     public getList(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<ApiList<AnimalDto>> {
        return super.getList(params, headers)
        .pipe(map((list: any) => { 
          return { 
            data: list.data.map(item => {
                return {
                    id: item.id,
                    ...item.attributes,
                    avatar: !!item.attributes.avatar?.data 
                      ? `${environment.uploadsHost}${item.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
                      : '/assets/icons/empty-goat.png',
                    lastReview: item.attributes.lastReview?.data ? { 
                      id: item.attributes.lastReview?.data.id,
                      ...item.attributes.lastReview?.data.attributes, 
                    } : null,
                    lastMilkYield: item.attributes.lastMilkYield?.data ? { 
                      id: item.attributes.lastMilkYield?.data.id,
                      ...item.attributes.lastMilkYield?.data.attributes, 
                    } : null,
                    photos: item.attributes.photos?.data?.map(photo => {
                      return {
                        id: photo.id,
                        ...photo.attributes
                      }
                    }),
                    mother: item.attributes.mother?.data ? { 
                      id: item.attributes.mother?.data.id,
                      ...item.attributes.mother?.data.attributes,
                      avatar: !!item.attributes.mother?.data.attributes.avatar?.data 
                      ? `${environment.uploadsHost}${item.attributes.mother?.data.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
                      : '/assets/icons/empty-goat.png', 
                    } : null,
                    father: item.attributes.father?.data ? { 
                      id: item.attributes.father?.data.id,
                      ...item.attributes.father?.data.attributes,
                      avatar: !!item.attributes.father?.data.attributes.avatar?.data 
                      ? `${environment.uploadsHost}${item.attributes.father?.data.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
                      : '/assets/icons/empty-goat.png' 
                    } : null,
                }
            }),
            meta: list.meta
          }; 
        }))
      }

      public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<AnimalDto> {
        return super.get(id, headers, params)
        .pipe(map(({ data }: any) => { 
            return {
                id: data.id,
                ...data.attributes,
                mother: data.attributes.mother?.data ? { 
                  id: data.attributes.mother?.data.id,
                  ...data.attributes.mother?.data.attributes, 
                } : null,
                father: data.attributes.father?.data ? { 
                  id: data.attributes.father?.data.id,
                  ...data.attributes.father?.data.attributes, 
                } : null,

                photos: data.attributes.photos?.data?.map(photo => {
                  return {
                    id: photo.id,
                    ...photo.attributes
                  }
                })
            }
        }))
      }

    uploadPhoto(file: File, id: number, field: string): Observable<any> {
        const formData = new FormData();        
        formData.append('files', file, `${file.name}.png`);
        formData.append('ref', 'api::animal.animal');
        formData.append('refId', String(id));
        formData.append('field', field);
        return this.httpClient.post(`${environment.host}photos`, formData);
    }

    changeFarm(cardId: EntityId, farmId: EntityId): Observable<any> {
      return this.httpClient.put(`${environment.host}animals/changefarm/${cardId}`, {}, {
        params: {
          farmId
        }        
      });
    }

    deletePhoto(id: number): Observable<any> {
        return this.httpClient.delete(`${environment.host}upload/files/${id}`);
    }

}
