import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScrollService } from './scroll.service';

@UntilDestroy()
@Directive({
    selector: '[appScrollAnchor]',
})
export class ScrollAnchorDirective implements OnInit, OnChanges {
    @Input() public mtScrollAnchor: string;
    @Input() public mtAnchorActivationMinOffset: number = 0;
    @Input() public mtAnchorActivationMaxOffset: number = 200;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private scrollService: ScrollService,
    ) { }

    public ngOnChanges({ mtScrollAnchor }: SimpleChanges): void {
        if (mtScrollAnchor) {
            this.renderNameAttribute();
        }
    }

    public ngOnInit(): void {
        this.renderNameAttribute();
        this.checkActive();
        this.scrollService.onScroll()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.checkActive());
    }

    public renderNameAttribute(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'app-scroll-anchor-name', this.mtScrollAnchor);
    }

    public checkActive(): void {
        if (this.isActive()) {
            this.scrollService.onAnchorActive.next(this.mtScrollAnchor);
        }
    }

    public isActive(): boolean {
        const elTop = this.getElTop();

        return elTop
            && elTop >= this.mtAnchorActivationMinOffset
            && elTop < this.mtAnchorActivationMaxOffset;
    }

    public getElTop(): number {
        return this.el.nativeElement.getBoundingClientRect().top;
    }

    ngOnDestroy() {
    }
}
