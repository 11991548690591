import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map,  throttleTime } from 'rxjs/operators';


export enum ScrollBehavior {
    AUTO = 'auto',
    INSTANT = 'instant',
    SMOOTH = 'smooth',
}

export enum ScrollPosition {
    START = 'start',
    CENTER = 'center',
    END = 'end',
    NEAREST = 'nearest',
}

export interface ScrollConfig {
    block: ScrollPosition;
    inline: ScrollPosition;
}

export const DefaultScrollConfig = {
    block: ScrollPosition.START,
    inline: ScrollPosition.NEAREST,
};

@UntilDestroy()
@Injectable()
export class ScrollService {
    public onAnchorActive: Subject<string> = new Subject();

    constructor() { }

    public onScroll(): Observable<Event> {
        return fromEvent(window, 'scroll', { capture: true })
            .pipe(
                //throttleTime(10),
                map(event => event),
                untilDestroyed(this, 'destroy')
            );
    }

    public scrollTo(el: HTMLElement, config?: ScrollConfig): void {
        if (!el) {
            return;
        }

        const resultConfig = {
            ...DefaultScrollConfig,
            ...config,
        };

        el.scrollIntoView(resultConfig);
    }

    public destroy(): void {

    }
}
