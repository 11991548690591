
export enum ErrorType {
    CONNECTION_TIMEOUT,
    INTERNAL_SERVER,
    VALIDATION_FORM,
    VALIDATION,
    CLIENT_ERROR,
    AUTH_ERROR,
    ACCESS_DENIED,
    BAD_REQUEST,
    NOT_ACCEPTABLE,
    NOT_FOUND
}

export interface ServerErrorDto {
    data: any;
    error: {
        name: string;
        message: string;
        status: number;    
        details: {
            errors: any[];
        }
    }

}

export interface ServerError {
    innerErrors: InnerServerError[];
    message?: string;
    type: ErrorType;
}

export interface InnerServerError {
    path?: string[];
    message: string;
    name?: string;
}

export function getServerError(responseError: ServerErrorDto): ServerError {
    return responseError && {
        innerErrors: getInnerServerErrors(responseError),
        message: responseError.error.message,
        type: typeErrorResolver(responseError.error.status),
    };
}

export function getInnerServerErrors(responseError: ServerErrorDto): InnerServerError[] {
    if (!responseError || !responseError.error.details.errors) {
        return [];
    }
    return responseError.error.details.errors;
}

function typeErrorResolver(status: number): ErrorType {

    if (status >= 500) {
        return ErrorType.INTERNAL_SERVER;
    }

    switch (status) {
        case 0:
            return ErrorType.CONNECTION_TIMEOUT;
        case 401:
            return ErrorType.AUTH_ERROR;
        case 403:
            return  ErrorType.ACCESS_DENIED;
        case 404:
            return  ErrorType.NOT_FOUND;
        case 405:
            return  ErrorType.NOT_ACCEPTABLE;
        case 400:
        case 406:
        case 409:
            return  ErrorType.CLIENT_ERROR;
        case 422:
            return  ErrorType.VALIDATION;
    }

    return  ErrorType.CLIENT_ERROR;

}

