import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
// import { Title } from '@angular/platform-browser';
// import { titleMapper } from 'src/app/shared/mappers/title';
// import { AppTranslateService } from 'src/app/core/translate/services/app-translate.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.userIsAuthorized()) {
      return true;
    }
    
    this.router.navigate(['/home']);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AuthGuardRouter implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.userIsAuthorized()) {
      return true;
    }
    
    this.router.navigate(['/home']);
    return false;
  }
}
