import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { NotificationTemplateDto } from './notification-templates.typings';

@Injectable({providedIn: 'root'})
export class NotificationTemplatesApiService extends ApiClient<NotificationTemplateDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}notification-templates`);
     }
}
