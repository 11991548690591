import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { BirthDto } from './births.typings';

@Injectable({providedIn: 'root'})
export class BirthsApiService extends ApiClient<BirthDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}births`);
     }

     public getList(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<ApiList<BirthDto>> {
        return super.getList(params, headers)
        .pipe(map((list: any) => { 
          return { 
            data: list.data.map(item => {
                return {
                    id: item.id,
                    ...item.attributes,
                    animal: {
                      id: item.attributes?.animal?.data?.id,
                      ...item.attributes?.animal?.data?.attributes,
                      avatar: !!item?.attributes?.animal?.data?.attributes?.avatar?.data 
                      ? `${environment.uploadsHost}${item?.attributes?.animal?.data?.attributes?.avatar?.data?.attributes.formats.thumbnail.url}`
                      : '/assets/icons/empty-goat.png',
                    }
                }
            }),
            meta: list.meta
          }; 
        }))
      }

      public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<BirthDto> {
        return super.get(id, headers, params)
        .pipe(map(({ data }: any) => { 
            return {
                id: data.id,
                ...data.attributes,
                animal: {
                  id: data.attributes?.animal?.data?.id,
                  ...data.attributes?.animal?.data?.attributes,
                  avatar: !!data?.attributes?.animal?.data?.attributes?.avatar?.data 
                    ? `${environment.uploadsHost}${data?.attributes?.animal?.data?.attributes?.avatar?.data?.attributes.url}`
                    : '/assets/icons/empty-goat.png',
                },
                children: data.attributes?.children?.data?.map(item => {
                  return {
                    id: item.id,
                    ...item.attributes,
                    avatar: !!item?.attributes?.avatar?.data 
                    ? `${environment.uploadsHost}${item?.attributes?.avatar?.data.attributes.url}`
                    : '/assets/icons/empty-goat.png',
                  }
                })
            }
        }))
      }

}
