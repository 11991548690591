import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiHttpParams, ApiHttpHeaders, ApiList, EntityId } from './api-client.typings';


export abstract class EntityApiClient<T> {
    protected constructor(protected httpClient: HttpClient, protected baseUrl: string) {
    }

    public get(headers?: ApiHttpHeaders, params: ApiHttpParams = {}): Observable<T> {
        return this.httpClient.get<T>(this.getURL(), { headers, params });
    }

    public update(data: T, headers?: ApiHttpHeaders): Observable<T> {
        return this.httpClient.put<T>(this.getURL(), data, { headers });
    }

    public delete(headers?: ApiHttpHeaders): Observable<void> {
        return this.httpClient.delete<void>(this.getURL(), { headers });
    }

    public patch<P>(body: P, headers?: ApiHttpHeaders): Observable<T[]> {
        return this.httpClient.patch<T[]>(this.getURL(), body, { headers });
    }

    protected getURL(id?: EntityId): string {
        return `${this.baseUrl}${id ? `/${id}` : ''}`;
    }

}
