import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { ApiHttpHeaders, ApiHttpParams, EntityId } from '../../api-client/api-client.typings';
import { FarmsApiService } from './farms-api.service';
import { FarmDto } from './farms.typings';

const CURRENCY_KEY = 'CURRENCY_KEY';

@Injectable({providedIn: 'root'})
export class FarmsProvider extends DataProvider<FarmDto> {

    constructor(protected api: FarmsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

    public get currency(): string {
        return localStorage.getItem(CURRENCY_KEY) || 'BYN';
    }

    public setCurrency(currency): void {
        localStorage.setItem(CURRENCY_KEY, currency || 'BYN');
    }

    public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<FarmDto> {
        return this.api.get(id, headers, params)
        .pipe(tap((data: FarmDto) => { 
            localStorage.setItem(CURRENCY_KEY, data.currency || 'BYN');
        }));
    }

    uploadPhoto(file: File, id: number, field: string, isErrorHandling = true): Observable<any> {
        return this.api.uploadPhoto(file, id, field).pipe(
            catchError(this.errorHandler.bind(this, isErrorHandling)),
        );
    }    

    deletePhoto(id: number, isErrorHandling = true): Observable<any> {
        return this.api.deletePhoto(id).pipe(
            catchError(this.errorHandler.bind(this, isErrorHandling)),
        );
    }
}
