import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NotificationService, Notifications } from 'src/app/shared/components/notification/notification.service';
import { AppTranslateService } from '../translate/services/app-translate.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly appTranslateService: AppTranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authenticationService.getAccessToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: request.headers.get('Authorization') || `Bearer ${token}`,
        }
      });
    }

    return next.handle(request)
      .pipe(
        catchError((err) => {
          console.error(err);
          if (err.status === 401) {
            this.authenticationService.clearStoredTokenData();
            // this.router.navigate(['/login']);
          }
          if (err.status === 403) {
            //this.router.navigate(['/access-denied']);
            this.notificationService.setText(this.appTranslateService.instant('fields.errors.access-denied'), Notifications.Error);
          }
          return throwError(err && {...err.error, status: err.error.status ? err.error.status : err.status});
        })
      );
  }
}
