import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { NotificationsApiService } from './notifications-api.service';
import { NotificationDto } from './notifications.typings';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { Observable, catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { NotificationTemplatesProvider } from '../notification-templates/notification-templates.provider';
import { NotificationTemplateDto } from '../notification-templates/notification-templates.typings';
import { environment } from 'src/environments/environment';


@Injectable({providedIn: 'root'})
export class NotificationsProvider extends DataProvider<NotificationDto> {
    templatesList: ApiList<NotificationTemplateDto> = null;
    
    constructor(protected api: NotificationsApiService, protected notificationTemplatesProvider: NotificationTemplatesProvider, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

    public getList(params: ApiHttpParams = {},
        headers: ApiHttpHeaders = {}, isErrorHandling = true): Observable<ApiList<NotificationDto>> {
        return this.api.getList({...params, 'populate[animals][populate][0]': 'avatar',}, {...headers})
            .pipe(switchMap((notificationsList: ApiList<NotificationDto>) => {
                return (this.templatesList ? of(this.templatesList) : this.notificationTemplatesProvider.getList())
                    .pipe(map(templatesList => {
                        this.templatesList = templatesList;                
                        return {
                            ...notificationsList,
                            data: notificationsList.data.map((notification: any) => {
                                const template: any = templatesList.data.find((tmpl: any) => tmpl.attributes.typeId === notification.attributes.id_notif_type);
                                return {
                                    ...notification.attributes,
                                    id: notification.id,
                                    animals: notification.attributes.animals.data.map(item => {
                                        return {
                                            id: item.id,
                                            ...item.attributes,
                                            avatar: !!item.attributes?.avatar?.data 
                                            ? `${environment.uploadsHost}${item.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
                                            : '/assets/icons/empty-goat.png',                                            
                                        }
                                    }),
                                    name: template?.attributes.name,
                                    smallDescription: template?.attributes.smallDescription,
                                    text: template?.attributes.template
                                }
                            })
                        } 
                    }))
            }))
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }
    public get(id: EntityId,
        headers: ApiHttpHeaders, params: ApiHttpParams = {}, isErrorHandling = true): Observable<NotificationDto> {
        return this.api.get(id, headers, params)
            .pipe(switchMap((notification: NotificationDto) => {
                return (this.templatesList ? of(this.templatesList) : this.notificationTemplatesProvider.getList())
                    .pipe(map(templatesList => {
                        this.templatesList = templatesList; 
                        const template: any = templatesList.data.find((tmpl: any) => tmpl.attributes.typeId === notification.id_notif_type);               
                        return {
                            ...notification,
                            name: template?.attributes.name,
                            smallDescription: template?.attributes.smallDescription,
                            text: template?.attributes.template
                        } 
                    }))
            }))
            .pipe(
                catchError(error => this.errorHandler(isErrorHandling, error)),
            );
    }

}
