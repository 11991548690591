
<app-search-input
    #resultPopup 
    appPopup
    [appPopupTemplate]="searchResult"
    class="global-search"
    [vm]="searchText">
</app-search-input> 
<ng-template #searchResult>
    <app-popup-container>
        <div *ngIf="animalsList.length > 0">
            <div class="list-header">{{ 'app.global-search.animals' | translate }}</div>
            <div class="items-list">
                <div 
                    class="item" 
                    *ngFor="let animal of animalsList" 
                    (click)="openAnimalPage(animal)"
                    fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
                    <div *ngIf="animal.avatar">
                        <img class="photo" [attr.src]="animal.avatar"/>
                    </div>
                    <div class="name">
                        {{ animal.name }}
                    </div>                                 
                </div>
                <tn-spinner *ngIf="isLoadAnimals" class="spinner"></tn-spinner>   
            </div>            
        </div>

        <div *ngIf="medicalRecordList.length > 0">
            <div class="list-header">{{ 'app.global-search.med-records' | translate }}</div>
            <div class="items-list">
                <div 
                    class="item" 
                    *ngFor="let medrecord of medicalRecordList" 
                    (click)="openMedRecordPage(medrecord)"
                    fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
                    <div *ngIf="medrecord.animal.avatar">
                        <img class="photo" [attr.src]="medrecord.animal.avatar"/>
                    </div>
                    <div class="name">
                        {{ medrecord.name }}
                    </div>
                    <div class="date">
                        {{ medrecord.date | dateLocalize }}
                    </div>                                     
                </div>
                <tn-spinner *ngIf="isLoadMedRecords" class="spinner"></tn-spinner>   
            </div>              
        </div>

        <div *ngIf="expensesList.length > 0">
            <div class="list-header">{{ 'app.global-search.expenses' | translate }}</div>
            <div class="items-list">
                <div 
                    class="item" 
                    *ngFor="let expense of expensesList" 
                    (click)="openExpensePage(expense)"
                    fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
                    <div class="date">
                        {{ expense.date | dateLocalize }}
                    </div>    
                    <div class="name">
                        {{ expense.name }}
                    </div>                                
                </div>
                <tn-spinner *ngIf="isLoadExpenses" class="spinner"></tn-spinner>   
            </div>              
        </div>

        <div *ngIf="profitsList.length > 0">
            <div class="list-header">{{ 'app.global-search.profits' | translate }}</div>
            <div class="items-list">
                <div 
                    class="item" 
                    *ngFor="let profit of profitsList" 
                    (click)="openProfitPage(profit)"
                    fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
                    <div class="date">
                        {{ profit.date | dateLocalize }}
                    </div>    
                    <div class="name">
                        {{ profit.name }}
                    </div>                                
                </div>
                <tn-spinner *ngIf="isLoadProfits" class="spinner"></tn-spinner>   
            </div>              
        </div>
       
    </app-popup-container>        
</ng-template>

