import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { ApiHttpHeaders, ApiHttpParams, ApiList, EntityId } from '../../api-client/api-client.typings';
import { NotificationDto } from './notifications.typings';

@Injectable({providedIn: 'root'})
export class NotificationsApiService extends ApiClient<NotificationDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}notifications`);
     }

     public get(id: EntityId, headers?: ApiHttpHeaders, params?: ApiHttpParams): Observable<NotificationDto> {
        return super.get(id, headers, params)
        .pipe(map(({ data }: any) => { 
            return {
                id: data.id,
                ...data.attributes,
                animals: data.attributes.animals.data.map(item => {
                    return {
                        id: item.id,
                        ...item.attributes,
                        avatar: !!item.attributes?.avatar?.data 
                        ? `${environment.uploadsHost}${item.attributes.avatar?.data?.attributes.formats.thumbnail.url}`
                        : '/assets/icons/empty-goat.png',                                            
                    }
                }),
            }
        }))
      }
}
