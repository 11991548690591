
export interface ProfitDto {
    id: number;
    name: string;
    description: string;
    price: number;
    date: string;
    type: ProfitTypes

}

export enum ProfitTypes {
    milk = 'milk',
    cheese = 'cheese',
    yogurt = 'yogurt',
    curd = 'curd',
    other = 'other'
}