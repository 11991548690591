import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppTranslateService } from './services/app-translate.service';
import { AppTranslateManagerService } from './services/app-translate-manager.service';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { ItaDateLocalizedPipe } from './pipes';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ItaDateLocalizedPipe,
    ],
    exports: [
        ItaDateLocalizedPipe,
    ],
    providers: [
        AppTranslateService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (translateService: AppTranslateService, translateManager: AppTranslateManagerService) => {
                return () => {
                    return new Promise(resolve => {
                        translateService.startObserve(translateManager.onChangeLang);
                        translateService.use(translateManager.getCurrentLang())
                            .subscribe(resolve);
                    });
                };
            },
            deps: [AppTranslateService, AppTranslateManagerService],
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true,
        },
    ],
})
export class AppTranslateModule {
}
