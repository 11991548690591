import { Injectable } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface TranslationObject {
  [key: string]: string;
}

@UntilDestroy()
@Injectable()
export class AppTranslateService {

  private subscriber: Subscription;

  constructor(
    protected translateService: TranslateService,
  ) {}

  public get currentLang(): string {
    return this.translateService.currentLang;
  }

  public startObserve(observer: Observable<string>): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
    this.subscriber = observer
      .pipe(
        untilDestroyed(this, 'destroy')
      )
      .subscribe((lang: string) => {
        this.use(lang);
      });
  }

  public use(lang: string): Observable<TranslationObject> {
    return this.translateService.use(lang);
  }

  destroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }

  public get(key: string | Array<string> = '', data: Array<any> = [], interpolateParams?: Object): Observable<string | any> {
    return !key ? of ('') : this.translateService.get(key, interpolateParams).pipe(
      map(instant => this.parseData(instant, data))
    );
  }

  public instant(key: string | Array<string>, data: Array<any> = [], interpolateParams?: Object): string | any {
    const instant = !key ? '' : this.translateService.instant(key.toString(), interpolateParams);
    return this.parseData(instant, data);
  }

  private parseData(value: string | any, data: Array<any>): string | any {
    for (let i = 0; i < data.length; i++) {
      value = value.replace(`[<${i + 1}>]`, data[i]);
    }
    return value;
  }

}
