import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ScrollService } from '../scroll.service';

@UntilDestroy()
@Component({
  selector: 'app-scroll-lazy-loader',
  templateUrl: './scroll-lazy-loader.component.html',
  styleUrls: ['./scroll-lazy-loader.component.scss']
})
export class ScrollLazyLoaderComponent implements AfterViewInit {
  @Input() public index: number;
  @Input() public initItemsCount: number = 15;
  @Input() public mtAnchorActivationMinOffset: number = 0;
  @Input() public mtAnchorActivationMaxOffset: number = 1500;

  @Input() public contentTmpl: TemplateRef<({})>;

  public init = false;

  protected scrollSubscription: Subscription = null;

  constructor(
    private el: ElementRef,
    private scrollService: ScrollService,
    protected readonly cd: ChangeDetectorRef,
  ) { }

  public ngAfterViewInit(): void {
      this.checkActive();
      this.scrollSubscription = this.scrollService.onScroll()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.checkActive();          
          });
  }

  public checkActive(): void {
    if (!this.init && (this.index <= this.initItemsCount || this.isActive())) {
      this.init = true;  
      this.cd.detectChanges();
      this.scrollSubscription?.unsubscribe();      
    }
  }

  public isActive(): boolean {
      const elTop = this.getElTop();

      return elTop
          && elTop >= this.mtAnchorActivationMinOffset
          && elTop < this.mtAnchorActivationMaxOffset;
  }

  public getElTop(): number {
      return this.el.nativeElement.getBoundingClientRect().top;
  }

}
