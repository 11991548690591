import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { NotificationsProvider } from 'src/app/core/services/notifications/notifications.provider';
import { NotificationDto } from 'src/app/core/services/notifications/notifications.typings';
import { PopupDirective } from 'src/app/shared/components/popup/popup.directive';
import { UpdateNotificationsMessage } from 'src/app/shared/components/system-notifications/notifications-list/notifications-list.component';

@UntilDestroy()
@Component({
  selector: 'notification-header',
  templateUrl: './notification-header.component.html',
  styleUrls: ['./notification-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationHeaderComponent implements OnInit {
  @ViewChild('appPopup', { read: PopupDirective,  static: false }) resultPopup: PopupDirective;

  public notifications: NotificationDto[] = [];
  
  constructor(
      protected notificationsProvider: NotificationsProvider,
      protected readonly messageBus: MessageBusService,
      protected readonly cd: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    this.messageBus.of(UpdateNotificationsMessage)
      .pipe(untilDestroyed(this))
      .subscribe(() => {        
        this.loadNotifications();
      });

    this.loadNotifications();
  }

  public loadNotifications(): void {
    this.notificationsProvider.getList({
      'filters[state][$eq]': '0'
    })
      .pipe(untilDestroyed(this))
      .subscribe((list) => {        
        this.notifications = list.data;
        this.cd.detectChanges();        
      });    
  }

  public closePopup(): void {
    this.resultPopup.close();
  }

  get countInfo(): string {
    return this.notifications ? (this.notifications.length < 100 ? this.notifications.length.toString() : '99+') : '0';
  }

}
