import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService, Notifications } from './notification.service';

@UntilDestroy()
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {

  Notifications = Notifications;
  constructor(public notificationService: NotificationService,
    private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.notificationService.changeState
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {

  }

}
