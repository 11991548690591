import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { BasePage } from 'src/app/core/base-pages/base-page';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { Location } from '@angular/common';
import { createValidatorsArray } from 'src/app/shared/validators/validators.mapper';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BasePage implements OnInit, OnDestroy {
  public showRecoveryForm = true;

  public vm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', createValidatorsArray([
      'required',
      'email',
    ])),
  });

  constructor(
    protected readonly router: Router,
    protected readonly authService: AuthenticationService,
    protected location: Location,
    protected readonly messageBus: MessageBusService,
    protected readonly cd: ChangeDetectorRef,
    private titleService: Title,
  ) { 
    super(router, authService, messageBus, location, cd);
    this.titleService.setTitle('Account - GoatFarms');
  }

  ngOnInit() {
  }

  onSubmit() {

    if(this.vm.valid) {
      this.showSpinner();
      this.authService.passwordRecovery({
        email: this.vm.get('email').value
      })
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.hideSpinner();
          this.showRecoveryForm = false;
          this.cd.detectChanges();
        }, (error: any) => {
          this.hideSpinner();
          this.cd.detectChanges();
        });      
    }

  }

  public resendEmail(): void {

  }

  ngOnDestroy() { }
}