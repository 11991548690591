<div class="relative" [ngClass]="{ showErrors: needShowErrors }">
  <div class="label">
      <label ellipsifyText>{{ label | translate }}</label> <span *ngIf='!!label && isRequired()' class='star'>*</span>
  </div>
  <mat-form-field appearance="outline">
    <div class="input">
      <input
          matInput
          type="text"
          [formControl]="innerControl"
          [matAutocomplete]="auto"
          [placeholder]="inputPlaceholder | translate"/>

      <mat-icon *ngIf="innerControl.value && innerControl.enabled"
         class="clear-icon"
        [class.disabled-icon]="vm.disabled"
        (click)="clear()">
        close
      </mat-icon>

      <mat-icon
        class="button_icon"
        [class.disabled-icon]="vm.disabled">
        expand_more
      </mat-icon>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (closed)="onBlur()">
      <mat-option  [title]="item.name" *ngFor="let item of dictList" [value]="item.name">
        <div class="coin__container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <img *ngIf="getImage(item)" [attr.src]="getImage(item)" class="coin-item__image"/>
          <div>{{ item?.name }}</div>                
        </div>
      </mat-option>
    </mat-autocomplete>

    <mat-error>
      <app-validations-container [control]="innerControl">
      </app-validations-container>
    </mat-error>

  </mat-form-field>
</div>



