import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { MilkYieldsApiService } from './milk-yields-api.service';
import { MilkYieldDto } from './milk-yields.typings';

@Injectable({providedIn: 'root'})
export class MilkYieldsProvider extends DataProvider<MilkYieldDto> {
    constructor(protected api: MilkYieldsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
