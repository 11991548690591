import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageBusService } from '../message-bus/message-bus-service';
import { BaseErrorMessage } from '../errors-handler/common.definitions';
import { ApiClient } from '../api-client/api-client';
import { ApiHttpParams, ApiHttpHeaders, ApiList, EntityId } from '../api-client/api-client.typings';
import { ServerErrorDto } from '../errors-handler/server-errors';
import { omit } from '../helpers/omit';

export class DataProvider<T> {
    protected constructor(
        protected api: ApiClient<T>,
        protected messageBus: MessageBusService
    ) { }

    public getList(params: ApiHttpParams = {},
                   headers: ApiHttpHeaders = {}, isErrorHandling = true): Observable<ApiList<T>> {
        return this.api.getList(params, {...headers})
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public getListEntity<D>(params: ApiHttpParams = {},
                            headers: ApiHttpHeaders = {}, isErrorHandling = true): Observable<ApiList<D>> {
        return this.api.getListEntity<ApiList<D>>(params, {...headers})
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public get(id: EntityId,
               headers: ApiHttpHeaders, params: ApiHttpParams = {}, isErrorHandling = true): Observable<T> {
        return this.api.get(id, headers, params)
            .pipe(
                catchError(error => this.errorHandler(isErrorHandling, error)),
            );
    }

    public getEntity<D>(id: EntityId,
                        headers: ApiHttpHeaders, params: ApiHttpParams = {}, isErrorHandling = true): Observable<D> {
        return this.api.getEntity<D>(id, headers, params)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public create<D>(data: D, headers: ApiHttpHeaders = {}, isErrorHandling = true): Observable<T> {
        return this.api.create(omit(data, [
            'id',
            'version',
          ]), headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public update<D>(id: EntityId, data: D, headers: ApiHttpHeaders, isErrorHandling = true): Observable<T> {
        return this.api.update(id, data, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public delete(id: EntityId, headers: ApiHttpHeaders, isErrorHandling = true): Observable<void> {
        return this.api.delete(id, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public patch<P>(body: P, headers: ApiHttpHeaders, isErrorHandling = true): Observable<ApiList<T>> {
        return this.api.patch(body, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public patchEntity<P>(id: EntityId, body: P, headers: ApiHttpHeaders, isErrorHandling = true): Observable<ApiList<T>> {
        return this.api.patchEntity(id, body, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    protected errorHandler(isErrorHandling: boolean, errorResponse: ServerErrorDto): Observable<never> {
      if (isErrorHandling) {
        this.messageBus.publish<BaseErrorMessage>(new BaseErrorMessage({...errorResponse}));
      }
      return throwError(errorResponse);
    }

    public getApiUrl(id?: EntityId): string {
        return this.api.getURL(id);
    }

}
