import { NgModule } from '@angular/core';
import { MessageBusModule } from 'src/app/core/message-bus/message-bus.module';
import { RouteConfigService } from './route-config.service';
import { RouteResolverPipe } from './route-resolver.pipe';

@NgModule({
    imports: [
        MessageBusModule
    ],
    declarations: [
        RouteResolverPipe
    ],
    exports: [RouteResolverPipe],
    providers: [RouteConfigService]
})
export class RouteConfigModule {

}
