import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { ReviewsApiService } from './reviews-api.service';
import { ReviewDto } from './reviews.typings';

@Injectable({providedIn: 'root'})
export class ReviewsProvider extends DataProvider<ReviewDto> {
    constructor(protected api: ReviewsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
