import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsifyTextDirective } from './ellipsify-text.directive';
import { NamedOutletDirective } from './named-outlet.directive';
import { ClipboardDirective } from './clipboard.directive';
import { ShowForLocaleDirective } from './show-for-locale.directive';



@NgModule({
  declarations: [
    EllipsifyTextDirective,
    NamedOutletDirective,
    ClipboardDirective,
    ShowForLocaleDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EllipsifyTextDirective,
    NamedOutletDirective,
    ClipboardDirective,
    ShowForLocaleDirective
  ],
})
export class SharedDirectivesModule { }
