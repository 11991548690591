import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { MedicalRecordsApiService } from './medical-records-api.service';
import { MedicalRecordDto } from './medical-records.typings';

@Injectable({providedIn: 'root'})
export class MedicalRecordsProvider extends DataProvider<MedicalRecordDto> {
    constructor(protected api: MedicalRecordsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }
}
