import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ApiHttpParams, ApiHttpHeaders, ApiList, EntityId } from './api-client.typings';


export abstract class ApiClient<T> {
    protected constructor(protected httpClient: HttpClient, protected baseUrl: string) {
    }

    public getList(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<ApiList<T>> {
        return this.httpClient.get<ApiList<T>>(this.getURL(), { params, headers });
    }

    public getListEntity<D>(params?: ApiHttpParams, headers?: ApiHttpHeaders): Observable<D> {
        return this.httpClient.get<D>(this.getURL(), { params, headers });
    }

    public get(id: EntityId, headers?: ApiHttpHeaders, params: ApiHttpParams = {}): Observable<T> {
        return this.httpClient.get<T>(this.getURL(id), { headers, params });
    }

    public getEntity<D>(id: EntityId, headers?: ApiHttpHeaders, params: ApiHttpParams = {}): Observable<D> {
        return this.httpClient.get<D>(this.getURL(id), { headers, params });
    }

    public create<D>(data: D, headers?: ApiHttpHeaders): Observable<T> {
        return this.httpClient.post<T>(this.getURL(), data, { headers });
    }

    public update<D>(id: EntityId, data: D, headers?: ApiHttpHeaders): Observable<T> {
        return this.httpClient.put<T>(this.getURL(id), data, { headers });
    }

    public delete(id: EntityId, headers?: ApiHttpHeaders): Observable<void> {
        return this.httpClient.delete<void>(this.getURL(id), { headers });
    }

    public patch<P>(body: P, headers?: ApiHttpHeaders): Observable<T[]> {
        return this.httpClient.patch<T[]>(this.getURL(), body, { headers });
    }

    public patchEntity<P>(id: EntityId, body: P, headers?: ApiHttpHeaders): Observable<T> {
        return this.httpClient.patch<T>(this.getURL(id), body, { headers });
    }

    public getURL(id?: EntityId): string {
        return `${this.baseUrl}${id ? `/${id}` : ''}`;
    }

}
