import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from '../modal/modal.module';
import { RouteModalComponent } from './route-modal.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        RouterModule,
        SharedDirectivesModule
    ],
    declarations: [RouteModalComponent],
    exports: [RouteModalComponent],
})
export class RouteModalModule {
}
