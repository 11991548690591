import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { trackByStringFn } from 'src/app/core/helpers/track-by';
import { AppTranslateService } from 'src/app/core/translate/services/app-translate.service';

interface DataValidator {
  key: string;
  data: Array<any>;
}

@Component({
  selector: 'tn-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})

export class ValidationComponent implements OnInit {

  @Input() control: UntypedFormControl;

  public trackByFn = trackByStringFn;

  get errors() {
    if (this.control && this.control.errors) {
      return Object.keys(this.control.errors);
    }
    return [];
  }

  constructor(
    private appTranslateService: AppTranslateService,
  ) {}

  ngOnInit() {
  }

  resolveError(value: string | DataValidator): Observable<string> {
    if (typeof value === 'string') {
      return this.appTranslateService.get(value);
    } else if (typeof value === 'object' && value.key !== undefined) {
        return this.appTranslateService.get(value.key, value.data);
    } else {
      return of('');
    }
  }

  isInValid(key: string) {
    return (this.control.invalid || this.control.disabled) && this.control.errors[key] && this.control.touched;
  }

}
