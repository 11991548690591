import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DeleteModalService } from './delete-modal/delete-modal.service';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { SvgImageModule } from 'src/app/shared/components/svg-image/svg-image.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        TranslateModule,
        MatButtonModule,
        SvgImageModule
    ],
    declarations: [
        DeleteModalComponent,
    ],
    exports: [
        DeleteModalComponent,
    ],
})
export class NotificationModalModule {
}
