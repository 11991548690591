import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { ShowInputsErrorMessage } from 'src/app/shared/components/inputs/inputs.typings';
import { BaseViewModel } from 'src/app/core/base-viewmodel/base-viewmodel';
import { createValidatorsArray } from 'src/app/shared/validators/validators.mapper';

// identifier: 'osipovich-corp@yandex.ru',
// password: 'corp123' 

@UntilDestroy()
@Component({
  selector: 'tn-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
  public isLoad = false;
  
  public user = new BaseViewModel(
    {
      login: new UntypedFormControl('', createValidatorsArray([
        'required',
      ])),
      password: new UntypedFormControl('', createValidatorsArray([
        'required',
      ])),
    }
  );

  err = '';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    protected readonly messageBus: MessageBusService,
    private router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {
  }

 public enter() {
    this.user.markAllAsTouched();
    this.user.updateValueAndValidity();

    if(this.user.valid) { 
      if(this.authService.userIsAuthorized()) {
        this.router.navigate(['animals']);
        return;
      }

      this.showSpinner();
      
      const login = this.user.get('login').value.trim();
      const password = this.user.get('password').value.trim();

      this.authService.login(login, password)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
            const prevUrl = this.router.parseUrl(this.router.url).queryParams.returnUrl || 'animals';
            this.hideSpinner();
            this.router.navigate([prevUrl]);
          },
          (error: any) => {
            this.hideSpinner();
            this.cd.detectChanges();
          });
    } else {
      this.messageBus.publish<ShowInputsErrorMessage>(new ShowInputsErrorMessage());
    }

  }

  protected showSpinner() {
    this.isLoad = true;
  }

  protected hideSpinner() {
    this.isLoad = false;
  }

  ngOnDestroy() {
  }
}
