import { Pipe, PipeTransform} from '@angular/core';
import { AppTranslateManagerService } from 'src/app/core/translate/services/app-translate-manager.service';

function get_gap(date1, date2: any) {
    const res: any = {};
    const d = new Date(date1);
    const y1 = date1.getFullYear(),
      y2 = date2.getFullYear();

    d.setFullYear(y2);
    res.years = y2 - y1 - (+d > +date2 ? 1 : 0);

    if (+d > +date2) {
      d.setFullYear(y2 - 1);
    }

    let months = 0;
    while (+d <= +date2) {
      d.setMonth(d.getMonth() + 1);
      months++;
    }

    if (+d > +date2) {
      d.setMonth(d.getMonth() - 1);
      months--;
    }

    res.months = months;
    res.days = Math.floor((+date2 - +d) / (1000 * 60 * 60 * 24));

    return res;
  }

  function formatter(date, translateService: AppTranslateManagerService) {
    const data = get_gap(new Date(date), new Date());

    const rgxps = [/^[2-9]?1$/, /^[2-9]?[2-4]$/, /1\d$|^[2-9]?[5-9]$|0$/];
    const years = ["date-pipe.year.1", "date-pipe.year.2_4", "date-pipe.year.5"],      
      months = ["date-pipe.month.1", "date-pipe.month.2_4", "date-pipe.month.5"],
      days = ["date-pipe.day.1", "date-pipe.day.2_4", "date-pipe.day.5"];

      const yStr = data.years > 0 ? `${data.years} ${translateService.translator.instant(years[rgxps.findIndex((r) => r.test(data.years + ""))])}` : '';
      const mStr = data.months > 0 ? `${data.months} ${translateService.translator.instant(months[rgxps.findIndex((r) => r.test(data.months + ""))])}` : '';
      const dStr = data.days > 0 ? ` ${data.days} ${translateService.translator.instant(days[rgxps.findIndex((r) => r.test(data.days + ""))])}` : '';
    return `${yStr} ${mStr} ${dStr}`;
  }

@Pipe({
    name: 'dateAgo',
    pure: false
})
export class DateAgoPipe implements PipeTransform {

  constructor(private translateManager: AppTranslateManagerService) {
  }

    transform(value: any, args?: any): any {
        if (value) {
            return formatter(value, this.translateManager);
        }
        return value;
    }

}