import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupDirective } from './popup.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopupContainerComponent } from './popup-container/popup-container.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
    ],
    declarations: [PopupDirective, PopupContainerComponent],
    exports: [PopupDirective, PopupContainerComponent],
})
export class PopupModule {
}
