import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { BaseViewModel } from 'src/app/core/base-viewmodel/base-viewmodel';
import { MainInfoPdfBuilder } from 'src/app/core/pdf/main-info/main-info';

interface CostsList {
  id: number;
  selected: boolean;
  name: string;
}

@Component({
  selector: 'app-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfExportComponent implements OnInit {
  setAllFilterOptions($event: any) {
    throw new Error('Method not implemented.');
  }

  @Input() public show: boolean = false;

  @Output() public save = new EventEmitter<number>();
  @Output() public close = new EventEmitter();

  public costList: CostsList[] = [
    { id: 0, selected: true, name: 'app.foods-page.title' },
    { id: 1, selected: true, name: 'app.expenses.med-expenses' },
    { id: 2, selected: true, name: 'app.equipments-page.title' },
    { id: 3, selected: true, name: 'app.expenses.other-expenses' },
    { id: 4, selected: true, name: 'app.profits.milk-profit' },
    { id: 5, selected: true, name: 'app.profits.cheese-profit' },
    { id: 6, selected: true, name: 'app.profits.curd-profit' },
    { id: 7, selected: true, name: 'app.profits.yoghurt-profit' },
    { id: 8, selected: true, name: 'app.profits.other-profuts' },
  ];

  public journalList: CostsList[] = [
    { id: 0, selected: true, name: 'app.reviews-page.title' },
    { id: 1, selected: true, name: 'app.medical.diseases' },
    { id: 2, selected: true, name: 'app.medical.procedures.title' },
    { id: 3, selected: true, name: 'app.global.fields.milk-yields' },
    { id: 4, selected: true, name: 'app.events-page.menu.insemination' },
    { id: 5, selected: true, name: 'app.events-page.menu.births' },
  ];

  public costItems: number[];

  public journalItems: number[];

  public viewModel = new BaseViewModel({
    dateRange: new UntypedFormControl(null),
    population: new UntypedFormControl(true),
  });

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly pdf: MainInfoPdfBuilder,
  ) {}

  ngOnInit(): void {
    this.costItems = this.costList.filter((item) => item.selected).map((item) => item.id);

    this.journalItems = this.journalList.filter((item) => item.selected).map((item) => item.id);
  }

  public exportData() {
    this.pdf.buildFullPdf(
      this.getControl('population').value,
      this.getControl('dateRange').value,
      this.costItems,
      this.journalItems,
    );
  }

  public closeModal() {
    this.show = false;
    this.close.emit();
  }

  public getControl(name: string): FormControl {
    return this.viewModel?.get(name) as FormControl;
  }

  public selectedCostChange(change: MatSelectionListChange): void {
    this.costItems = [];
    for (let index = 0; index < change.source._value.length; index++) {
      const element = Number(change.source._value[index]);
      this.costItems.push(element);
    }
  }

  public selectedJournalChange(change: MatSelectionListChange): void {
    this.journalItems = [];
    for (let index = 0; index < change.source._value.length; index++) {
      const element = Number(change.source._value[index]);
      this.journalItems.push(element);
    }
  }
}
