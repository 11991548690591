import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollTriggerDirective } from './scroll-trigger.directive';
import { ScrollAnchorDirective } from './scroll-anchor.directive';
import { ScrollService } from './scroll.service';
import { ScrollLazyLoaderComponent } from './scroll-lazy-loader/scroll-lazy-loader.component';

const directives = [
    ScrollTriggerDirective,
    ScrollAnchorDirective,
    ScrollLazyLoaderComponent
];

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [...directives, ScrollLazyLoaderComponent],
    exports: [...directives],
    providers: [ScrollService],
})
export class ScrollModule {
}
