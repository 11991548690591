<app-modal
  [opened]="show"
  [config]="{ width: 500, height: 450, minWidth: 400 }"
  [useBackDropClose]="false"
>
    <app-modal-container headerTitle="Login" (closeModal)="cancel()">
        <form class="form">
            <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                <div fxFlex="100" fxFlex.xs="100" class='login_div' fxLayout="column">
                    <div class="login-form">
                        <app-input 
                            class="login-field" 
                            [vm]="identity?.get('login')" 
                            nameControl="login" 
                            label="app.login-page.login" 
                            placeholder="app.login-page.login">
                        </app-input>
                        <app-input  
                            *ngIf="!sendCodeMode" 
                            class="password-field" 
                            [vm]="identity?.get('password')" 
                            nameControl="password" 
                            label="Code" 
                            placeholder="app.login-page.password" 
                            objectType="password">
                        </app-input>        
        
                        <div [ngClass]="{hidden: err.length === 0}" class="ita-validation form__error">{{ err | translate }}</div>
        
                        <button
                            *ngIf="!sendCodeMode"
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="login-btn btn-color upper_case"
                            [title]="'app.login.login-button' | translate"
                            (click)="login()">
                            <div ellipsifyText>
                                {{ 'app.login-page.login-btn' | translate }}
                            </div>
                        </button>
                        <button
                           *ngIf="sendCodeMode"
                            mat-raised-button
                            color="primary"
                            type="submit"
                            class="login-btn btn-color upper_case"
                            [title]="'app.login.send-code-btn' | translate"
                            (click)="sendCode()">
                            <div ellipsifyText>
                                {{ 'app.login-page.send-code-btn' | translate }}
                            </div>
                        </button>     
                    </div>
                </div>
            </div>
            <tn-spinner *ngIf="isLoad"></tn-spinner>
        </form>
    </app-modal-container>
</app-modal>

