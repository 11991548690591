<div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <img fxFlex="21px" class="logo" src="/assets/icons/empty-goat.png" alt="">
        <div>© {{ 'app.global.fields.rights' | translate }}, 2023, goat-farms.</div>    
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
        <a class="link" href="/public-offer" target="_blank">{{'app.global.fields.public-contract' | translate}}</a>
        <a class="link" href="/privacy-policy" target="_blank">{{'app.global.fields.privacy-policy' | translate}}</a>    
    </div>
</div>

