<form class="form" [formGroup]="user" (ngSubmit)="enter()">
    <div class="content" fxLayout="row" fxLayoutAlign="center start" fxLayout.xs="column" fxFlexFill>   
        <div fxFlex="550px" fxFlex.xs="100" class='login_div' fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img fxFlex="150px" class="logo" src="/assets/icons/empty-goat.png" alt="">
                <h1 class="title">
                    {{ 'app.login.title' | translate }}
                </h1>
                <p class="notice">
                    {{ 'app.login.not-registered' | translate }} <a class="link" routerLink="/registration">{{ 'app.login.registration' | translate }}</a>
                </p>
            </div>
            <div class="login-form" fxFlex="100">
                <app-input class="login-field" [vm]="user?.get('login')" nameControl="login" placeholder="app.login.login"></app-input>
                <app-input class="password-field" [vm]="user?.get('password')" nameControl="password" placeholder="app.login.password" objectType="password"></app-input>


                <div [ngClass]="{hidden: err.length === 0}" class="ita-validation form__error">{{ err | translate }}</div>
                <div>{{ 'app.login.password-forget' | translate }} <a class="link" routerLink="/forgot-password">{{ 'app.login.restore-password' | translate }}</a></div>

                <!--accent-button class="page-save accent-button" text="app.login.login-button"></accent-button-->
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.login.login-button' | translate">
                    <div ellipsifyText>
                        {{ 'app.login.login-button' | translate }}
                    </div>
                </button>    
            </div>
        </div>
    </div>
    <tn-spinner *ngIf="isLoad"></tn-spinner>
</form>
<app-footer class="footer"></app-footer>
