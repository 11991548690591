import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import IMask from 'imask';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { parseDatetoStr } from 'src/app/core/helpers/date';
import { isRequiredField } from 'src/app/shared/validators/validators';
import { AppDateAdapter } from '../datepicker/date-adapter.component';

const INVALID_STATUS = 'INVALID';

@UntilDestroy()
@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter }
  ]
})
export class DateRangeComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() vm: UntypedFormControl;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() minDate = new Date(1900, 1, 1);
  @Input() maxDate = new Date(2100, 1, 1);

  @ViewChild('startDate') startDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;

  protected maskStart = null;
  protected maskEnd = null;

  public rangeGroup = new UntypedFormGroup(
  {
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null)
  });

  constructor(
    private readonly cd: ChangeDetectorRef
    ) { }

  private dateRangeValidator() {
    return (control: AbstractControl) => {
      if ((this.rangeGroup) && (this.rangeGroup.status === INVALID_STATUS)) {
          return {statusInvalid: ''};
      } else {
        return null;
      }
    };
  }

  ngOnInit() {
    this.vm.setValidators([this.dateRangeValidator()]);
    this.fillControls();
    this.rangeGroup.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      const dates = {
        minDate: parseDatetoStr(this.rangeGroup.get('start').value),
        maxDate: parseDatetoStr(this.rangeGroup.get('end').value)
      }
      //   `${parseDatetoStr(this.rangeGroup.get('start').value)}`,
      //   `${parseDatetoStr(this.rangeGroup.get('end').value)}`,
      // ];
      this.vm.setValue(dates);
      this.cd.detectChanges();
    });
  }

  private fillControls() {
    if (this.vm && this.vm.value && Array.isArray(this.vm.value)) {
      if (this.vm.value.length === 2) {
        if ((this.vm.value[0]) && (this.vm.value[0] !== 'null')) {
          this.rangeGroup.get('start').setValue(new Date(this.vm.value[0]));
        }
        if ((this.vm.value[1]) && (this.vm.value[1] !== 'null')) {
          this.rangeGroup.get('end').setValue(new Date(this.vm.value[1]));
        }
        this.cd.detectChanges();
      }
    }
  }

  public isRequired(): boolean {
    return !!this.placeholder && isRequiredField(this.vm);
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    this.maskStart = IMask(
      this.startDate.nativeElement,
      {
        mask: Date,
        lazy: false
      }
    );

    this.maskEnd = IMask(
      this.endDate.nativeElement,
      {
        mask: Date,
        lazy: false
      }
    );
  }

}
