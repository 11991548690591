import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    NotificationsListComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule
  ],
  exports: [NotificationsListComponent]
})
export class SystemNotificationsModule { }
