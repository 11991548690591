<div class="relative" [ngClass]="{ showErrors: needShowErrors }">
  <div class="label"><label ellipsifyText>{{ label | translate }}</label> <span *ngIf='label && isRequired()' class='star'>*</span></div>
  <mat-form-field appearance="outline">
    <input
      matInput
      [attr.name]="nameControl"
      [attr.autocomplete]="autocompleteName" 
      [type]="objectType === 'password' ? (showPassword ? 'text' : 'password') : objectType"
      [formControl]="viewModel"
      [placeholder]="inputPlaceholder | translate"
      (keypress)="keyPressNumbers($event)">
    <mat-hint *ngIf='!!hint' [ngStyle]="{'color': !!colorHint ? colorHint : 'inherit' }">
      {{ hint | translate }}
    </mat-hint>
    <mat-icon 
      *ngIf="objectType === 'password'" 
      matSuffix 
      class="mat-suffix"
      (click)="togglePasswordVisibility()">
      {{ showPassword ? 'visibility_off' : 'visibility' }}
    </mat-icon>
    <mat-error>
      <app-validations-container
        [control]="viewModel"
        [disabled]="disabled">
      </app-validations-container>
    </mat-error>
  </mat-form-field>
</div>
