import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DeleteModalService {
    show = false;
    state: Subject<boolean>;
    changeField = new Subject();
    public text1: string;
    public text2: string;
    public showConfirmButton: boolean;
    public cancelButtonCaption: string;
    public confirmButtonCaption: string;

    connect(text1 = 'app.notification-modal.delete-modal.text1',
            text2 = 'app.notification-modal.delete-modal.text2',
            showConfirmButton = true,
            cancelButtonCaption = 'app.notification-modal.delete-modal.cancel',
            confirmButtonCaption = 'app.delete_element'): Observable<boolean> {
        this.text1 = text1;
        this.text2 = text2;
        this.showConfirmButton = showConfirmButton;
        this.confirmButtonCaption = confirmButtonCaption;
        this.cancelButtonCaption = cancelButtonCaption;
        this.state = new Subject<boolean>();
        this.setShow();
        return this.state;
    }

    setShow() {
        this.show = true;
        this.changeField.next(true);
    }

    setHide(cond: boolean) {
        this.show = false;
        this.state.next(cond);
        this.changeField.next(cond);
    }
}
