import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() public vm: UntypedFormControl = new UntypedFormControl();

  constructor() { }

  ngOnInit(): void {
  }

  public get autocompleteName(): string {
    return `new-SearchInput`;
  }

}
