import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from 'src/app/shared/components/spinner/spinner.module';
import { InputModule } from 'src/app/shared/components/inputs/input/input.module';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { LoginComponent } from './login/login.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FarmsAutocompleteModule } from 'src/app/shared/components/inputs/farms-autocomplete/farms-autocomplete.module';
import { AccessDeniedPageComponent } from './access-denied-page/access-denied-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { RouterModule } from '@angular/router';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';



@NgModule({
  declarations: [
    LoginModalComponent,
    LoginComponent,
    RegistrationPageComponent,
    AccessDeniedPageComponent,
    ForgotPasswordComponent,
    ResetPasswordPageComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HttpClientModule,
    InputModule,
    MatButtonModule,
    ModalModule,
    MatButtonToggleModule,
    FarmsAutocompleteModule,
    RouterModule,
    FooterModule
  ],
  exports: [
    LoginModalComponent, 
    LoginComponent, 
    RegistrationPageComponent, 
    AccessDeniedPageComponent,
    ResetPasswordPageComponent
  ]
})
export class AuthModule { }
