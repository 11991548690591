
<div class="main" cdkDropListGroup>
    <app-toolbar class="header"
                 [sidenav]="snav"
                 (onMenuClick)="menuClick()">
    </app-toolbar>
    <app-offline-notification></app-offline-notification>
    <div class="page-container">        
        <mat-sidenav-container>
            <mat-sidenav #snav [mode]="'over'"  [fixedInViewport]="true" fixedTopGap="72">
                <div fxLayout="column" class="flat-menu">
                    <div fxLayout="column">
                        <a *ngFor="let link of leftsideMenuNavLinks"
                                        class="menu-item"
                                        [routerLink]="link.url"
                                        [class.active]="activeLink?.indexOf(link.url) !== -1 "
                                        (click)="snav.close()"> {{ link.label | translate }} </a>                 
                    </div>        
                    <app-profile-panel class="profile-panel" layout="column" (onMenuClick)="snav.close()"></app-profile-panel>                    
                </div>

            </mat-sidenav>

            <mat-sidenav-content>
                <div cdkScrollable class="right-side-content">
                    <div class="page-body">
                        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
                            <div class="main__nav-menu" fxLayout="row" fxFlex="100">
                                <div *ngIf="showBackBtn" fxFlex="60px">
                                    <mat-icon class="back-btn" (click)="goBack()">arrow_back_ios</mat-icon>
                                </div>
                                <nav mat-tab-nav-bar fxFlex="100">
                                    <a mat-tab-link *ngFor="let link of navLinks"
                                        (click)="activeLink = link.url"
                                        [routerLink]="link.url"
                                        [active]="activeLink?.indexOf(link.url) !== -1 "> {{ link.label | translate }} </a>                
                                </nav>                            
                            </div>
                            <div fxLayout="row" fxFlex="350px" fxFlex.xs="100" fxFlex.sm="100">
                                <app-global-search fxFlex="100" class="global-search"></app-global-search>  
                            </div>
                              
                        </div>    
                        
                        <router-outlet></router-outlet>                        
                    </div>
                </div>             
            </mat-sidenav-content>
        </mat-sidenav-container>

    </div>
    <app-footer></app-footer>            
</div>

<app-route-modal
    [config]="{ width: 'auto', height: 'auto', minWidth: 600 }"
    [isFullscreen]="false">
</app-route-modal>
<app-delete-modal>    
</app-delete-modal>
<app-pwa-update-notification></app-pwa-update-notification>
