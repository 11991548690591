<div class="home-container">
    <div fxLayout="row" fxLayoutAlign="start center" class="header">
        <img class="logo" src="/assets/icons/empty-goat-white.png"/>
        <div class="text-logo">Goat<span class="farms">Farms</span></div>
        <div class="links__container" fxLayout="row" fxLayoutAlign="start center">
            <div appScrollTrigger mtScrollAnchorLink="capabilities" class="link__item">{{ 'app.home.possibilities.title' | translate }}</div>
            <div appScrollTrigger mtScrollAnchorLink="additions" showForLocale="ru" class="link__item left-separator">{{ 'app.home.addIns' | translate }}</div>
            <div showForLocale="ru" class="link__item left-separator">
                <a class="white-link" href="http://articles.goat-farms.by">{{ 'app.home.knowledgebase' | translate }}</a>                
            </div>
            <div appScrollTrigger mtScrollAnchorLink="contacts" showForLocale="ru" class="link__item left-separator">{{ 'app.home.contacts' | translate }}</div>
        </div> 
        <language-switcher class="language-switcher"></language-switcher>
        <a routerLink="/login" class="auth__btn">{{ 'app.global.personalAccount' | translate }}</a>      
    </div>
    <div class="banner__conteiner" fxLayout="row" fxLayout.xs="column"  fxLayoutGap="10px"> 
        <div class="main-description">
            <div>{{ 'app.home.description.service' | translate }}</div>
            <div style="padding-left: 1rem;">
                <div>- {{ 'app.home.description.inventory' | translate }}</div>        
                <div>- {{ 'app.home.description.expirience' | translate }}</div>
                <div>- {{ 'app.home.description.profitability' | translate }}</div>                
                <div>- {{ 'app.home.description.pedigrees' | translate }}</div>               
                <div>- {{ 'app.home.description.sales' | translate }}</div>
                <div>- {{ 'app.home.description.sertificate' | translate }}</div> 
                <div>- {{ 'app.home.description.learn' | translate }}</div>                 
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="export-pdf">
                <img fxFlex="28px" src="assets/images/export-pdf.png"/>
                <div>
                    {{ 'app.home.description.export' | translate }}
                </div>
                
            </div>
        </div>

        <div routerLink="/registration" class="register-btn">{{ 'app.global.registration' | translate }}</div>   
    </div>

    <div  class="benefits-list" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="6px">
        <div appScrollAnchor mtScrollAnchor="capabilities" class="section__title">{{ 'app.home.possibilities.title' | translate }}</div>
        <div  class="sub-section" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="20px">
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <img class="section_img" src="/assets/images/animals-list.JPG"/>
            </div>
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <div class="item-number" fxFlex="45px" fxFlex.xs="30px">1</div>
                <div class="item-text">
                    <div class="item-title">{{ 'app.home.possibilities.accounting-animals.title' | translate }}</div>
                    <div class="item-description">
                        <div>{{ 'app.home.possibilities.accounting-animals.description' | translate }}</div>
                        <div class="ml-2">
                            <div>- {{ 'app.home.possibilities.accounting-animals.option-1' | translate }};</div>
                            <div>- {{ 'app.home.possibilities.accounting-animals.option-2' | translate }};</div>
                            <div>- {{ 'app.home.possibilities.accounting-animals.option-3' | translate }};</div>
                            <div>- {{ 'app.home.possibilities.accounting-animals.option-4' | translate }}.</div>                              
                        </div>
                    </div>                    
                </div>
            </div>            
        </div>
        <div class="separator"></div>
        <div class="sub-section" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="20px">
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <div class="item-number" fxFlex="45px" fxFlex.xs="30px">2</div>
                <div class="item-text">                    
                    <div class="item-title">{{ 'app.home.possibilities.pedigree.title' | translate }}</div>
                    <div class="item-description">
                        {{ 'app.home.possibilities.pedigree.description-part-1' | translate }}
                        <div class="mt-2">{{ 'app.home.possibilities.pedigree.description-part-2' | translate }}</div>   
                    </div> 
                </div>
            </div>
            <div fxFlexOrder.xs="-1"  fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <img class="section_img" src="/assets/images/pedigree.JPG"/>
            </div>              
        </div>
        <div class="separator"></div>
        <div class="sub-section" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="20px">
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <img class="section_img" src="/assets/images/med-list.JPG"/>
            </div>  
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <div class="item-number" fxFlex="45px" fxFlex.xs="30px">3</div>
                <div class="item-text">                    
                    <div class="item-title">{{ 'app.home.possibilities.med-cards.title' | translate }}</div>
                    <div class="item-description">
                        {{ 'app.home.possibilities.med-cards.description-part-1' | translate }}
                        <div class="ml-2">
                            <div>- {{ 'app.home.possibilities.med-cards.option-1' | translate }};</div> 
                            <div>- {{ 'app.home.possibilities.med-cards.option-2' | translate }};</div>
                            <div>- {{ 'app.home.possibilities.med-cards.option-3' | translate }}.</div>                            
                        </div>
                        <div class="mt-2">{{ 'app.home.possibilities.med-cards.description-part-2' | translate }}</div> 
                        <div class="mt-2">{{ 'app.home.possibilities.med-cards.description-part-3' | translate }}</div>
                    </div> 
                </div>
            </div>              
        </div>
        <div class="separator"></div>
        <div class="sub-section" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="20px">
            <div fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <div class="item-number" fxFlex="45px" fxFlex.xs="30px">4</div>
                <div class="item-text">
                    <div class="item-title">{{ 'app.home.possibilities.profit-calculator.title' | translate }}</div>
                    <div class="item-description">
                        {{ 'app.home.possibilities.profit-calculator.description-part-1' | translate }}
                        <div class="mt-2">{{ 'app.home.possibilities.profit-calculator.description-part-2' | translate }}</div> 
                        <div>{{ 'app.home.possibilities.profit-calculator.description-part-3' | translate }}</div>    
                    </div>
                </div>
            </div>
            <div  fxFlexOrder.xs="-1" fxFlex="50" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="6px">
                <img class="section_img" src="/assets/images/profit-chart.JPG"/>
            </div>                
        </div>
        <div class="separator"></div>
    </div>
    <div showForLocale="ru" appScrollAnchor mtScrollAnchor="additions" class="tariffs">
        <div class="section__title">Тарифы</div>
        <div fxLayout="row" fxLayout.xs="column" >
            <div fxFlex="100" fxLayout="column" class="tariff-item free">
                <div class="tariff-title">Базовый</div>
                <div class="tariff-cost">Предоставляется БЕСПЛАТНО</div>
                <div class="tariff-description">
                    <div>- Учет животных (до 25 особей)</div>
                    <div>- Регистрация удоев</div>
                    <div>- Регистрация доходов и расходов</div>
                    <div>- Ведение мед. карточек животных</div>
                    <div>- Регистрация осмотров</div>
                    <div>- Регистрация покрытий и автоматический расчет даты родов</div>
                    <div>- Калькулятор доходности фермы</div>
                    <div>- Автоматическое составление родословной</div>
                    <div>- Открытые карточки животных</div>
                    <div>- Подготовка "Свидетельства о происхождении" к печати</div>
                    <div>- Экспорт данных в PDF-формате</div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
    </div>
    <div showForLocale="ru" appScrollAnchor mtScrollAnchor="knowledge_base" class="knowledge_base">
        <div class="section__title">{{ 'app.home.knowledgebase' | translate }}</div>
        <div fxLayout="column" fxLayoutGap="16px" class="item-text">        
            <div>
                <video ngClass="video-pl" ngClass.xs="video-pl-mobile" controls="controls" poster="assets/videos/goat-farms-fast-start_poster.JPG">
                    <source src="assets/videos/goat-farms-fast-start.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
                    Тег video не поддерживается вашим браузером. 
                    <a href="assets/videos/goat-farms-fast-start.mp4">Скачайте видео</a>.
                </video>                
            </div>
        </div>
        <div class="separator"></div>
    </div>
    <div showForLocale="ru" appScrollAnchor mtScrollAnchor="contacts" class="contacts">
        <div class="section__title">Контакты</div>
        <div class="item-text">
            <div class="item-description">
                Если Вы нашли какую-либо ошибку или проблему на сайте, у вас появились замечания или предложения, пожалуйста, сообщите об этом нашим специалистам на почту <a class="link" href="mailto:goat-farms@mail.ru">goat-farms@mail.ru</a>.
            </div>            
        </div>

    </div>
    <app-footer class="footer"></app-footer>
</div>
