import { AbstractControl, ValidatorFn, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import regulars from '../utils/regulars';

export function required(control: AbstractControl) {
  if (control.value == null || (typeof control.value === 'string' && !control.value.trim())) {
    return {
      required: 'fields.errors.required'
    };
  }
  return null;
}

export function requiredItems(control: AbstractControl) {
  if (control.value == null || (control.value.length === 0)
    || (typeof control.value === 'string' && !control.value.trim())) {
    return {
      required: 'fields.errors.required'
    };
  }
  return null;
}

export function latinDigits(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9a-zA-Z]+$/;
  return reg.test(control.value) ? null : { latinDigits: 'fields.errors.latinDigits' };
}

export function simplePassword(control: AbstractControl) {
  const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
  if (control.value != null && !reg.test(control.value)) {
    return {
      password: 'fields.errors.simplePassword'
    };
  }
  return null;
}

export function latinAndDigitsAndSpecialSymbol(control: AbstractControl) {
  const reg = /^[A-z0-9!#$%&'*+-/=?^_`{|}~]+$/;
  if (control.value !== undefined && control.value !== null && !reg.test(control.value)) {
    return {
      latinAndDigitsAndSpecialSymbol: {
        key: 'fields.errors.latinAndDigitsAndSpecialSymbol',
        data: ['{}']
      }
    };
  }
  return null;
}

export function latinAndDigitsAndSpecialSymbolAndHyphenAndDot(control: AbstractControl) {
  const reg = /^[^-.][0-9a-zA-Z/!#$%&'*+-/=?^_`{|}~.]+$/;
  if (control.value !== undefined && control.value !== null && !reg.test(control.value)) {
    return {
      validUsername: 'fields.errors.latinAndDigitsAndSpecialSymbolAndHyphenAndDot'
    };
  }
  return null;
}

export function latinDigitsAndMinus(control: AbstractControl) {
  const reg = /^[0-9a-zA-Z-]*$/;
  if (control.value != null && !reg.test(control.value)) {
    return {
      latinDigitsAndMinus: 'fields.errors.latinDigitsAndMinus'
    };
  }
  return null;
}

export function latinAndCyrillicAndDigits(control: AbstractControl) {
  const reg = /^[0-9a-zA-Zа-яёА-ЯЁ]+$/;
  if (control.value != null && !reg.test(control.value)) {
    return {
      latinAndCyrillicAndDigits: 'fields.errors.latinAndCyrillicAndDigits'
    };
  }
  return null;
}

export function upperLatinCyrillicAndDigits(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9A-ZА-ЯЁ]+$/;
  return reg.test(control.value) ? null : { upperLatinCyrillicAndDigits: 'fields.errors.upperLatinCyrillicAndDigits' };
}

export function latinAndCyrillicAndDigitsAndSpaceAndSpec(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9a-zA-Zа-яёА-Я\s./-]+$/;
  return reg.test(control.value) ? null : { latinAndCyrillicAndDigitsAndSpaceAndSpec: 'fields.errors.latinAndCyrillicAndDigitsAndSpaceAndSpec' };
}

export function latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen(control: AbstractControl) {
  const reg = /^[0-9a-zA-Zа-яёА-Я-!@#$%^&№*()\s]+$/;
  if (control.value !== undefined && control.value !== null && !reg.test(control.value)) {
    return {
      latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen: 'fields.errors.latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen'
    };
  }
  return null;
}

export function latinAndCyrillicAndDigitsAndHyphen(control: AbstractControl) {
  const reg = /^[0-9a-zA-Zа-яёА-Я-\s]+$/;
  if (control.value !== undefined && control.value !== null && !reg.test(control.value)) {
    return {
      latinAndCyrillicAndDigitsAndHyphen: 'fields.errors.latinAndCyrillicAndDigitsAndHyphen'
    };
  }
  return null;
}

export function notSpaceBegin(control: AbstractControl) {
  const reg = /(^[^\s].*$)|(^$)/;
  if (control.value !== undefined && control.value !== null && !reg.test(control.value)) {
    return {
      notSpaceBegin: 'fields.errors.notSpaceBegin'
    };
  }
  return null;
}

export function latin(control: AbstractControl) {
  const reg = /^[a-zA-Z]/;
  return control.value != null && reg.test(control.value) ? null
    : {
      latin: 'fields.errors.latin'
    };
}

export function latinAndUnderscore(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[a-zA-Z_]+$/;
  return reg.test(control.value) ? null : { latinAndUnderscore: 'fields.errors.latinAndUnderscore' };
}

export function latinAndHyphen(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[a-zA-Z-]+$/;
  return reg.test(control.value) ? null : { latinAndHyphen: 'fields.errors.latinAndHyphen' };
}

export function latinAndHyphenAndSpaceAndApostrophe(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[a-zA-Z-\s']+$/;
  return reg.test(control.value) ? null : { latinAndHyphen: 'fields.errors.latinAndHyphen' };
}

export function latinAndHyphenAndSpaceAndApostropheAndDot(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[a-zA-Z-\s'.]+$/;
  return reg.test(control.value) ? null : { latinAndHyphen: 'fields.errors.latinAndHyphenAndSpaceAndApostropheAndDot' };
}

export function cyrillic(control: AbstractControl) {
  const reg = /^[а-яёА-ЯЁ]+/;
  return control.value != null && reg.test(control.value) ? null
    : {
      cyrillic: 'fields.errors.cyrillic'
    };
}

export function cyrillicAndHyphenAndSpaceAndApostrophe(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[а-яёА-ЯЁ-\s']+$/;
  return reg.test(control.value) ? null : { cyrillicAndHyphen: 'fields.errors.cyrillicAndHyphen' };
}

export function cyrillicAndHyphenAndSpaceAndApostropheAndDot(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[а-яёА-ЯЁ-\s'.]+$/;
  return reg.test(control.value) ? null : { cyrillicAndHyphen: 'fields.errors.cyrillicAndHyphenAndSpaceAndApostropheAndDot' };
}

export function cyrillicAndHyphen(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[а-яёА-ЯЁ-]+$/;
  return reg.test(control.value) ? null : { cyrillicAndHyphen: 'fields.errors.cyrillicAndHyphen' };
}

export function positiveDigits(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9]+$/;
  return reg.test(control.value) ? null
    : {
      digits: 'fields.errors.positiveDigits'
    };
}

export function digits(control: AbstractControl) {
  const reg = /^-*[0-9]*$/;
  return !(control.value) || reg.test(control.value) ? null
    : {
      digits: 'fields.errors.digits'
    };
}

export function digitsAndHyphen(control: AbstractControl) {
  const reg = /^[0-9]*[-]?[0-9]+$/;
  return control.value != null && reg.test(control.value) ? null
    : {
      digits: 'fields.errors.digitsAndHyphen'
    };
}

export function digitsAndSlash(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9\/\\]+$/;
  return reg.test(control.value) ? null : { digits: 'fields.errors.digitsAndSlash' };
}

export function digitsAndSlashAndHyphen(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const reg = /^[0-9-\/\\]+$/;
  return reg.test(control.value) ? null : { digits: 'fields.errors.digitsAndSlashAndHyphen' };
}

export function positiveCommaDigits(control: AbstractControl) {
  if (control.value === null || control.value === undefined) {
    return null;
  }
  const reg = /^[0-9]*[.,]?[0-9]+$/;
  return reg.test(control.value) ? null
    : {
      digits: 'fields.errors.digits'
    };
}

export function positiveCommaDigitsWithLimit(limit: number) {
  return (control: AbstractControl) =>  {
    if (!control.value) {
      return null;
    }
    const reg = new RegExp(`^[0-9]*[.,]?[0-9]{1,${limit}}$`, 'g');
    return reg.test(control.value) ? null
    : { positiveCommaDigitsWithLimit: {
        key: 'fields.errors.positiveCommaDigitsWithLimit',
        data: [limit]
      }
    };
  };
}

export function lengthReg(length: number) {
  return (control: AbstractControl) => {
    return control.value != null && control.value.length === length ? null
      : { length: {
          key: 'fields.errors.lengthReg',
          data: [length]
        }
      };
  };

}

export function minLength(digit: number) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    return control.value.length >= digit ? null
      : { length: {
          key: 'fields.errors.minlength',
          data: [digit]
        }
      };
  };

}

export function maxLength(digit: number) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    return String(control.value).length <= digit ? null
      : { maxLength: {
          key: 'fields.errors.maxlength',
          data: [digit]
        }
      };
  };
}


export function minLengthDigit(digit: number) {
  return (control: AbstractControl) =>
    control.value != null && String(Math.abs(control.value)).length >= digit ? null
      : {
        minLength: {
          key: 'fields.errors.minlength',
          data: [digit]
        }
      };
}

export function maxLengthDigit(digit: number) {
  return (control: AbstractControl) =>  {
    if (!control.value) {
      return null;
    }
    return String(Math.abs(control.value)).length <= digit
      ? null
      : { maxLength: {
          key: 'fields.errors.maxlength',
          data: [digit]
        }
      };
  };
}

export function maxLengthWith2SignsAfterComma(digit: number, positive = false) {
  return (control: AbstractControl) => {
    let value = control.value;
    if (!positive) {
      value = Math.abs(control.value);
    }
    const reg = createRealRegexp(digit);

    return reg.test(value) && (positive ? value >= 0 : true)
      ? null
      : { maxLengthWith2SignsAfterComma: {
          key: `fields.errors.maxLengthWith2SignsAfterComma${positive === true ? 'onlyPositive' : ''}`,
          data: [digit]
        }
      };
  };
}

export function min(value: number) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    return +(control.value) >= value
      ? null
      : { min: {
          key: 'fields.errors.min',
          data: [value]
        }
      };
  };
}

export function max(value: number) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    return +(control.value) <= value ? null
      : { max: {
          key: 'fields.errors.max',
          data: [value]
        }
      };
  };
}

export function email(control: AbstractControl) {
  const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;
  return control.value != null && reg.test(control.value)
    ? null
    : {
      email: 'fields.errors.email'
    };
}

export function url(control: AbstractControl) {
  var URL_REGEXP = /^(https?|ftp):\/\/(((([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/;

  if(URL_REGEXP.test(control.value)) {
    return null;
  } else {
    return { invalidUrl: 'fields.errors.invalidUrl' };
  }

}

function checkNumbers(num1: number, num2: any): boolean {
  if (typeof num1 === 'number' && typeof num2 === 'number') {
    return (num1 >= 0) && (num2 >= 0) && (num2 > num1);
  } else {
    return false;
  }
}

function checkRange(num1: number, num2: number, value: number): boolean {
  return (num2 - num1 > 0) && (num2 - num1 <= value - 1);
}

export function validEmails(control: AbstractControl) {
  let valid = true;
  const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i;

  if (control.value) {
    const emails = control.value.split(';');
    for (const emailStr of emails) {
      if (!reg.test(emailStr)) {
        valid = false;
        break;
      }
    }
  }
  return valid ? null : { email: 'fields.errors.email' };
}

function generateErrorObj(error: any, valid: boolean, maxNumbers: number): any {
  if (!valid) {
    return {
      key: 'fields.errors.big-range',
      data: [maxNumbers]
    };
  } else {
    return error;
  }
}

export function validArray(maxNumbers: number) {
  return (control: AbstractControl) => {
    let valid = true;
    let error: any = 'fields.errors.invalid-range';
    if (control.value) {
      const numbersString = control.value.split('-');
      if (numbersString.length === 2) {
        if (!!numbersString[0] && !!numbersString[1]) {
          valid = checkNumbers(+(numbersString[0].trim()), +(numbersString[1].trim()));
          if (valid) {
            valid = checkRange(+(numbersString[0].trim()), +(numbersString[1].trim()), maxNumbers);
            error = generateErrorObj(error, valid, maxNumbers);
            valid = valid ? (numbersString[0].trim().length === numbersString[1].trim().length) : valid;
          }
        } else {
          valid = false;
        }
      } else {
        valid = false;
      }
    }
    return valid ? null : { message: error };
  };
}

export function dateInTheFuture(control: AbstractControl) {
  if (!regulars.checkDate(control.value) || control.pristine) {
    return null;
  }
  const date = new Date(control.value);
  return regulars.getOnlyDate(date) <= regulars.currentDate()
    ? null
    : {
      dateInTheFuture: 'fields.errors.dateInTheFuture'
    };
}

export function dateInThePast(control: AbstractControl) {

  if (!regulars.checkDate(control.value) || control.pristine) {
    return null;
  }
  const date = new Date(control.value);
  return +regulars.currentDate() <= +regulars.getOnlyDate(date)
    ? null
    : {
      dateInThePast: 'fields.errors.dateInThePast'
    };
}

export function dateLeastYear(value: number) {
  return (control: AbstractControl) => {
    if (!regulars.checkDate(control.value)) {
      return null;
    }
    const date = new Date(control.value);
    const diff = Math.floor(regulars.currentDate().getTime() - date.getTime());
    const year = 1000 * 60 * 60 * 24 * 30 * 12;
    return diff >= year * value
      ? null
      : {
        dateLeastYear: {
          key: 'fields.errors.dateLeastYear',
          data: [value]
        }
      };
  };
}

export function dateMoreYear(value: number) {
  return (control: AbstractControl) => {
    if (!regulars.checkDate(control.value)) {
      return null;
    }
    const date = new Date(control.value);
    const diff = Math.floor(regulars.currentDate().getTime() - date.getTime());
    return diff <= Date.now() - new Date().setFullYear(new Date().getFullYear() - value)
      ? null
      : {
        dateMoreYear: {
          key: 'fields.errors.dateMoreYear',
          data: [value]
        }
      };
  };
}

export function canValidateDecorator(validator: ValidatorFn, canExecute: Function): ValidatorFn {
  return (control: AbstractControl) => {
    return canExecute && canExecute() ? validator(control) : null;
  };
}


export function validateAllFields(formGroup: UntypedFormGroup | UntypedFormArray) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      validateAllFields(control);
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof UntypedFormArray) {
      validateAllFields(control);
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
  formGroup.updateValueAndValidity({ onlySelf: true });
}

export function createRealRegexp(digit: number): RegExp {
  return new RegExp(
    `^([0-9]{1,${digit}})$|(^[0-9]{1,${digit - 2}}[.|,]([0-9]{0,2})$)|(^[0-9]{1,${digit - 1}}[.|,]([0-9]{0,1})$)`,
    's'
  );
}

export function createStringRegexp(digit: number): RegExp {
  return new RegExp(`^[0-9a-zA-Zа-яА-Я-!@#$%^&*()\s]{0,${digit}}$`, 's');
}


export function isRequiredField(control: AbstractControl): boolean {
  const validator = control && control.validator && control.validator(control);
  return !!validator
    && !!validator.hasOwnProperty
    && !!validator.hasOwnProperty('required');
}


export default {
  required,
  latinDigits,
  simplePassword,
  latinAndDigitsAndSpecialSymbol,
  latinAndDigitsAndSpecialSymbolAndHyphenAndDot,
  latinDigitsAndMinus,
  latinAndCyrillicAndDigits,
  upperLatinCyrillicAndDigits,
  latinAndCyrillicAndDigitsAndSpaceAndSpec,
  latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen,
  latinAndCyrillicAndDigitsAndHyphen,
  notSpaceBegin,
  latin,
  latinAndHyphen,
  latinAndHyphenAndSpaceAndApostrophe,
  latinAndHyphenAndSpaceAndApostropheAndDot,
  cyrillic,
  cyrillicAndHyphenAndSpaceAndApostrophe,
  cyrillicAndHyphenAndSpaceAndApostropheAndDot,
  cyrillicAndHyphen,
  positiveDigits,
  digits,
  digitsAndHyphen,
  digitsAndSlash,
  digitsAndSlashAndHyphen,
  positiveCommaDigits,
  positiveCommaDigitsWithLimit,
  minLength,
  lengthReg,
  maxLength,
  minLengthDigit,
  maxLengthDigit,
  maxLengthWith2SignsAfterComma,
  min,
  max,
  email,
  url,
  checkNumbers,
  checkRange,
  validEmails,
  generateErrorObj,
  validArray,
  dateInTheFuture,
  dateInThePast,
  dateLeastYear,
  dateMoreYear,
  canValidateDecorator,
  validateAllFields,
  createRealRegexp,
  isRequiredField,
  createStringRegexp
};

