<app-modal
    [opened]="opened"
    [isFullscreen]="isFullscreen"
    [closeEscDisabled]="closeEscDisabled"
    [useBackDropClose]="useBackDropClose"
    [config]="config"
    (closeModal)="closeModal.emit()"
    (afterOpen)="afterOpen.emit()"
    (afterClosed)="afterClosed.emit()"
>
    <named-outlet [name]="id"></named-outlet>
</app-modal>
