import { DatePipe } from '@angular/common';

export const weekDaysCount = 7;
export const dayHours = 24;

export function dateFormat(date: Date): string {
  const pipe = new DatePipe('en');
  return date ? pipe.transform(toUTC(new Date(date)), 'yyyy/MM/dd') : '-';
}

export function dateTimeFormat(date: Date): string {
  const pipe = new DatePipe('en');
  return date ? pipe.transform(toUTC(new Date(date)), 'MM/dd/yyyy, h:mm a') : '-';
}

export function timeFormat(date: Date): string {
  const pipe = new DatePipe('en');
  return date ? pipe.transform(toUTC(new Date(date)), 'h:mm a') : '-';
}

export function toUTC(datetime): Date {
  const myDate = (typeof datetime === 'number')
    ? new Date(datetime)
    : datetime;

  if (!myDate || (typeof myDate.getTime !== 'function')) {
    return null;
  }

  const getUTC = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
  return new Date(getUTC - offset); // UTC - OFFSET
}

export function fromUTC(datetime): Date {
  const myDate = (typeof datetime === 'number')
    ? new Date(datetime)
    : datetime;

  if (!myDate || (typeof myDate.getTime !== 'function')) {
    return null;
  }

  const getUTC = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
  return new Date(getUTC + offset); // UTC + OFFSET
}

export function parseDatetoStr(value: Date | string): string {
  try {
    if (!value) {
      return null;
    }
    const date = typeof value === 'string' ? new Date(value) : value;
    return `${date.getUTCFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  } catch (e) {
    console.log('parseDatetoStr', e);
    return null;
  }
}


export function getNumberOfDays(start, end): number {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

export const getFirstDayOfYear = (date: Date): Date => {
  return new Date(date.getFullYear(), 0, 1);
};

export const getLastDayOfYear = (date: Date): Date => {
  return new Date(date.getFullYear(), 12, 0);
};

export const getFirstDayOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayOfMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getFirstDayOfWeek = (date: Date): Date => {
  return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - ((date.getDay() || weekDaysCount) - 1),
  );
};

export const getLastDayOfWeek = (date: Date): Date => {
  const firstDayOfWeek = getFirstDayOfWeek(date);
  return new Date(
      firstDayOfWeek.getFullYear(),
      firstDayOfWeek.getMonth(),
      firstDayOfWeek.getDate() + 6
  );
};

export const switchDay = (date: Date, step: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + step);

  return newDate;
};

export const switchMonth = (date: Date, step: number): Date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + step);

  return newDate;
};

export const switchYear = (date: Date, step: number): Date => {
  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + step);

  return newDate;
};

export const switchWeek = (date: Date, step: number): Date => {
  return switchDay(date, step * weekDaysCount);
};