<mat-checkbox
  color="primary"
  [formControl]="vm"
  (change)="onChange.emit($event.checked)">
  <ng-content></ng-content>
</mat-checkbox>

<!--app-validations-container
  [control]="vm"
  [nameControl]="nameControl">
</app-validations-container-->
