import { UserModelBasic } from "../services/users/users.typings";

export interface JWTToken {
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
    jti: string;
    scope: string;
}

export interface UserIdentity {
    jwt: string;
    user: UserModelBasic;
}

export interface JWTDecoded {
    userId: string;
    authorities: string[];
    client_id: string;
    exp: number;
    jti: string;
    scope: string[];
}

export class UserLogoutMessage {
    public static channel = 'UserLogoutMessage';
    constructor() { }
}

export class UserAuthMessage {
    public static channel = 'UserAuthMessage';
    constructor() { }
}
