import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { fromUTC, toUTC } from 'src/app/core/helpers/date';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

  createDate(year: number, month: number, date: number): Date {
    return toUTC(new Date(year, month, date));
  }

  parse(value: any): Date {
    if (typeof value === 'string') {
      const strDate = value
        .replace(/\/|,/g, '.')
        .replace(/_/g, '')
        .split('.');

      if (strDate[0] && strDate[1] && strDate[2] && strDate[2].length >= 4) {
        const year = Number(strDate[2].substring(0, 4));
        const month = Number(strDate[1]) - 1;
        const date = Number(strDate[0]);
        return toUTC(new Date(year, month, date));
      }

      return null;
    }

    return null;
  }

  format(date: Date, displayFormat: any): string {

    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${this._to2digit(day)}/${this._to2digit(month)}/${year}`;
    } else {
      return fromUTC(date).toLocaleDateString('ru-RU');
    }
  }

  private _to2digit(n: number) {
      return (`00${n}`).slice(-2);
  }
}
