<app-modal
  [opened]="deleteModalService.show"
  [config]="{ width: 426, height: 392, minWidth: 426 }"
  [useBackDropClose]="false">
    <div class="container">
        <div class="line"></div>
        <div class="content-container">
            <img src="/assets/icons/danger.svg" alt="">
            <h2>{{ 'app.notification-modal.delete-modal.title' | translate }}</h2>
            <div class="text-body text1">{{ deleteModalService.text1 | translate }}</div>
            <div class="text-body text2">{{ deleteModalService.text2 | translate }}</div>
        </div>
        <footer class="button-container">
            <button
                mat-raised-button color="primary"
                *ngIf="deleteModalService.showConfirmButton"
                class="active-btn" 
                (click)="deleteModalService.setHide(true)">
                {{ deleteModalService.confirmButtonCaption | translate }}
            </button>

            <button mat-stroked-button (click)="deleteModalService.setHide(false)">
                {{ deleteModalService.cancelButtonCaption | translate }}
            </button>
        </footer>
    </div>
</app-modal>
