import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { NotificationTemplatesApiService } from './notification-templates-api.service';
import { NotificationTemplateDto } from './notification-templates.typings';


@Injectable({providedIn: 'root'})
export class NotificationTemplatesProvider extends DataProvider<NotificationTemplateDto> {
    constructor(protected api: NotificationTemplatesApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

}
