import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EntityApiClient } from '../../api-client/api-entity-client';
import { SettingsDto } from './settings.typings';

@Injectable({providedIn: 'root'})
export class SettingsApiService extends EntityApiClient<SettingsDto> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, `${environment.host}settings/`);
     }
}
