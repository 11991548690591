import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { JWTDecoded, JWTToken, UserAuthMessage, UserIdentity, UserLogoutMessage } from './auth.typing';
import { Observable, of, throwError } from 'rxjs';
import { Identity, UserModelBasic } from '../services/users/users.typings';
import { UserApiService } from '../services/users/users-api.service';
import { MessageBusService } from '../message-bus/message-bus-service';
import { Router } from '@angular/router';
import { ServerErrorDto } from '../errors-handler/server-errors';
import { BaseErrorMessage } from '../errors-handler/common.definitions';


const BASE_URL: string = environment.host;
const USERS_API_HOST = `${environment.host}users`;

const RESTORE_GRANT_TYPE = 'refresh_token';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const CURRENT_USER = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private refreshTokenTimeoutId: any =  null;
  private currentUserDetails: UserModelBasic | null = null;

  constructor(private readonly http: HttpClient,
              protected messageBus: MessageBusService,
              private readonly router: Router,
              private userApiService: UserApiService) {

      if(localStorage.getItem(CURRENT_USER)) {
        this.currentUserDetails = JSON.parse(localStorage.getItem(CURRENT_USER) || '');
      }
      
  }

  public get currentUser(): Readonly<UserModelBasic | null> {
    return this.currentUserDetails;
  }

  public getCurrentUser(forceUpdate = false): Observable<UserModelBasic | null> {
    return this.currentUserDetails && !forceUpdate
      ? of(this.currentUserDetails) 
      : this.http.get<UserModelBasic>(`${USERS_API_HOST}/me`)
         .pipe(tap((user) => {
            localStorage.setItem(CURRENT_USER, JSON.stringify(user));
         }));
  }

  public login(identifier: string, password: string): Observable<UserModelBasic> {
    return this.authorize({
      identifier: identifier.trim(),
      password: password.trim()
    });
  }

  public registration(data): Observable<UserModelBasic> {
    return this.http.post<UserIdentity>(
      `${BASE_URL}auth/local/register`,
      data,
      { }
    ).pipe(
      tap((userIdentity: UserIdentity) => {
        this.setAccessToken(userIdentity.jwt);
        
        this.startRefreshTokenTimer();
        this.currentUserDetails = userIdentity.user;
        localStorage.setItem(CURRENT_USER, JSON.stringify(userIdentity.user));
      }),
      map((userIdentity: UserIdentity) => userIdentity.user)
    )
    .pipe(catchError(this.errorHandler.bind(this, true)));
  }

  public passwordRecovery(email): Observable<any> {
    return this.http.post<any>(
      `${BASE_URL}auth/forgot-password`,
      email,
      { }
    )
    .pipe(catchError(this.errorHandler.bind(this, true)));
  }

  public resetPassword(data): Observable<any> {
    return this.http.post<any>(
      `${BASE_URL}auth/reset-password`,
      data,
      { }
    ).pipe(
      tap((userIdentity: UserIdentity) => {
        this.setAccessToken(userIdentity.jwt);
        
        this.startRefreshTokenTimer();
        this.currentUserDetails = userIdentity.user;
        localStorage.setItem(CURRENT_USER, JSON.stringify(userIdentity.user));
      }),
      map((userIdentity: UserIdentity) => userIdentity.user)
    )
    .pipe(catchError(this.errorHandler.bind(this, true)));
  }

  public logout(): Observable<any> {
      this.clearStoredTokenData();
      this.router.navigate(['home']);
      return of(null);
  }

  public restoreSession(): void {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return;
    }

    const formData = new FormData();
    formData.append('grant_type', RESTORE_GRANT_TYPE);
    formData.append('refresh_token', refreshToken);

    // this.authorize(formData).subscribe();
  }

  private authorize(identity: Identity): Observable<UserModelBasic> {
    return this.http.post<UserIdentity>(
      `${BASE_URL}auth/local`,
      identity,
      { }
    ).pipe(
      tap((userIdentity: UserIdentity) => {
        this.setAccessToken(userIdentity.jwt);
        // this.setRefreshToken(jwtToken.refresh_token); ???
        this.startRefreshTokenTimer();
        this.currentUserDetails = userIdentity.user;
        localStorage.setItem(CURRENT_USER, JSON.stringify(userIdentity.user));
      }),
      map((userIdentity: UserIdentity) => userIdentity.user)
    )
    .pipe(catchError(this.errorHandler.bind(this, true)));
  }

  public clearStoredTokenData() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(CURRENT_USER);
    this.currentUserDetails = null;
    this.stopRefreshTokenTimer();
    this.messageBus.publish<UserLogoutMessage>(new UserLogoutMessage());
  }

  private decodeToken(token: string): JWTDecoded {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  public userIsAuthorized(): boolean {
    return !!this.getAccessToken() /*&& !!this.getRefreshToken() && !!this.currentUserDetails*/;
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify({
      exp: Date.now() / 1000 + 30 * 60,
      token
    }));
  }

  private setRefreshToken(token: string): void {
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public getAccessToken(): string | null {
      const tokenData = localStorage.getItem(ACCESS_TOKEN);

      if(tokenData) {
        try {
          return JSON.parse(tokenData).token; 
        } catch {
          return tokenData;
        }
      } 

      return null;   

  }

  public getTokenExpTime(): number | null {
    const tokenData = localStorage.getItem(ACCESS_TOKEN);

    if(tokenData) {
      try {
        return JSON.parse(tokenData).exp; 
      } catch {
        return Date.now() / 1000 + 30 * 60;
      }
    } 

    return null;   
  }

  public startRefreshTokenTimer() {
    const token = this.getAccessToken();
    if (!token) {
      return;
    }
    // parse json object from base64 encoded jwt token
    const jwtToken = this.decodeToken(token);

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.stopRefreshTokenTimer();
    this.refreshTokenTimeoutId = setTimeout(() => this.restoreSession(), timeout);
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeoutId) {
      clearTimeout(this.refreshTokenTimeoutId);
    }
  }

  protected errorHandler(isErrorHandling: boolean, errorResponse: ServerErrorDto, requestUrl: string): Observable<never> {
    if (isErrorHandling) {
      this.messageBus.publish<BaseErrorMessage>(new BaseErrorMessage({...errorResponse }));
    }
    return throwError(errorResponse);
  }
}
