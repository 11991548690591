import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface TranslateStore {
    [key: string]: string | TranslateStore;
}

export interface TranslateManager {
    createTranslation(lang: string, translation: TranslateStore): Observable<TranslateStore>;
}

const SELECTED_LANGUAGE_KEY = 'selected_language';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AppTranslateManagerService implements TranslateManager {

    public onChangeLang: Observable<string>;

    constructor(
        private readonly translateService: TranslateService
    ) {
        this.onChangeLang = this.translateService
            .onLangChange
            .pipe(
                map((event: LangChangeEvent) => event.lang),
                untilDestroyed(this)
            );
    }

    public changeLang(lang: string): void {
        this.translateService.use(lang);
        localStorage.setItem(SELECTED_LANGUAGE_KEY, lang);
    }

    public getDefaultLang(): string {
        return 'ru';
    }

    public setCurrentLanguage() {
        this.translateService.use(this.getCurrentLang());
      }

    public getCurrentLang(): string {
        return localStorage.getItem(SELECTED_LANGUAGE_KEY) || this.getDefaultLang();
    }

    public getBaseTranslations(lang: string): TranslateStore {
        return this.translateService.translations[lang];
    }

    public createTranslation(lang: string, translation: TranslateStore): Observable<TranslateStore> {
        return of(Object.assign({}, this.getBaseTranslations(lang) || {}, translation));
    }

    public get(value: string | string[]): Observable<string | string[] | any> {
        return this.translateService.get(value);
    }

    get translator(): TranslateService {
        return this.translateService;
    }
}
