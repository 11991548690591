import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContainerComponent {
}
