import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslateManager, TranslateStore } from './app-translate-manager.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/environments/environment';

export type RootLoader = (http: HttpClient) => MultiTranslateHttpLoader;
export type ChildLoader = (appTranslateStore: TranslateManager, http: HttpClient) => AppTranslateHttpLoader;

export class AppTranslateHttpLoader implements TranslateLoader {

    public static createLoaderForRoot(...urls: Array<{ prefix: string, suffix: string }>): RootLoader {
        return (http: HttpClient): MultiTranslateHttpLoader => {
            return new MultiTranslateHttpLoader(http, [
                ...urls,
            ]);
        };
    }

    public static createLoaderForChild(prefix: string): ChildLoader {
        return (translateManager: TranslateManager, http: HttpClient) => {
            return new AppTranslateHttpLoader(translateManager, http, prefix, `.json?version=${environment.version}`);
        };
    }

    constructor(
        private readonly store: TranslateManager,
        private readonly http: HttpClient,
        private readonly prefix: string,
        private readonly suffix: string,
    ) {
    }

    public getTranslation(lang: string): Observable<TranslateStore> {
        return this.http.get(this.prefix + lang + this.suffix)
            .pipe(
                mergeMap((result) => this.store.createTranslation(lang, result as TranslateStore))
            );
    }
}
