<form class="form">
    <div class="content" fxLayout="row" fxLayoutAlign="center start" fxLayout.xs="column" fxFlexFill>   
        <div fxFlex="550px" fxFlex.xs="100" class='login_div' fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                <img fxFlex="100px" class="logo" src="/assets/icons/empty-goat.png" alt="">
                <h1 class="title">
                    {{ 'app.reset-password.title' | translate }}
                </h1>
            </div>
            <div class="login-form" fxFlex="100">
                <app-input 
                    class="field" 
                    [vm]="viewModel?.get('password')"
                    label="app.reset-password.new-password"  
                    placeholder="app.reset-password.enter-new-password" 
                    objectType="password">
                </app-input>
                <app-input 
                    class="field"
                    [vm]="viewModel?.get('passwordConfirmation')"
                    label="app.reset-password.password-confirmation" 
                    placeholder="app.reset-password.password-confirmation" 
                    objectType="password">
                </app-input>

                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="login-btn btn-color upper_case"
                    [title]="'app.reset-password.password-update' | translate"
                    (click)="onSubmit()">
                    <div ellipsifyText>
                        {{ 'app.reset-password.password-update' | translate }}
                    </div>
                </button>    
            </div>
        </div>
    </div>
    <tn-spinner *ngIf="isLoad"></tn-spinner>
</form>
