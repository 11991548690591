import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { ScrollService } from './scroll.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Directive({
    selector: '[appScrollTrigger]',
})
export class ScrollTriggerDirective implements OnInit {
    @Input() public mtScrollAnchorLink: string;
    @Input() public mtScrollTriggerActiveClass: string = 'is-active';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        public scrollToService: ScrollService,
    ) { }

    public ngOnInit(): void {
        this.scrollToService.onAnchorActive
            .pipe(
                map(value => this.mtScrollAnchorLink === value),
                distinctUntilChanged(),
                untilDestroyed(this, 'destroy')
            )
            .subscribe(value => this.setActiveClass(value));
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event): void {
        event.preventDefault();

        const linkedEl = this.getElementRefByAttr(this.mtScrollAnchorLink);
        this.scrollToService.scrollTo(linkedEl);
        this.scrollToService.onAnchorActive.next(this.mtScrollAnchorLink);
     }

    public setActiveClass(isAdd: boolean): void {
        if (isAdd) {
            this.renderer.addClass(this.el.nativeElement, this.mtScrollTriggerActiveClass);
        } else {
            this.renderer.removeClass(this.el.nativeElement, this.mtScrollTriggerActiveClass);
        }
    }

    public getElementRefByAttr(attr: string): HTMLElement {
        return this.el.nativeElement.ownerDocument.querySelector(`[app-scroll-anchor-name=${attr}]`);
    }

    public destroy(): void {

    }

}
