import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-offer',
  templateUrl: './public-offer.component.html',
  styleUrls: ['./public-offer.component.scss']
})
export class PublicOfferComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
