import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { ApiHttpHeaders, ApiHttpParams } from '../../api-client/api-client.typings';
import { EntityDataProvider } from '../../data-provider/entity-data-provider';
import { SettingsApiService } from './settings-api.service';
import { SettingsDto } from './settings.typings';

@Injectable({providedIn: 'root'})
export class SettingsProvider extends EntityDataProvider<SettingsDto> {
    protected settings$: ReplaySubject<SettingsDto> =  new ReplaySubject<SettingsDto>(1);

    public get currentSettings$(): Observable<SettingsDto> {
        return this.settings$;
    }

    constructor(protected api: SettingsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

    public get(headers: ApiHttpHeaders, params: ApiHttpParams = {}, isErrorHandling: boolean  = true): Observable<SettingsDto> {
        return this.api.get(headers, params)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
                tap((settings) => {
                    this.settings$.next(settings);
                })
            );
    }
}
