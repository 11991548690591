import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageBusService } from '../message-bus/message-bus-service';
import { BaseErrorMessage } from '../errors-handler/common.definitions';
import { ApiHttpParams, ApiHttpHeaders, ApiList } from '../api-client/api-client.typings';
import { EntityApiClient } from '../api-client/api-entity-client';
import { ServerErrorDto } from '../errors-handler/server-errors';

export class EntityDataProvider<T> {
    protected constructor(
        protected api: EntityApiClient<T>,
        protected messageBus: MessageBusService
    ) { }

    public get(headers: ApiHttpHeaders, params: ApiHttpParams = {}, isErrorHandling: boolean  = true): Observable<T> {
        return this.api.get(headers, params)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public update(data: T, headers: ApiHttpHeaders, isErrorHandling: boolean  = true): Observable<T> {
        return this.api.update(data, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public delete(headers: ApiHttpHeaders, isErrorHandling: boolean  = true): Observable<void> {
        return this.api.delete(headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    public patch<P>(body: P, headers: ApiHttpHeaders, isErrorHandling: boolean  = true): Observable<ApiList<T>> {
        return this.api.patch(body, headers)
            .pipe(
                catchError(this.errorHandler.bind(this, isErrorHandling)),
            );
    }

    protected errorHandler(isErrorHandling: boolean, errorResponse: ServerErrorDto): Observable<never> {
        isErrorHandling && this.messageBus.publish<BaseErrorMessage>(new BaseErrorMessage(errorResponse));
        return throwError(errorResponse);
    }

}
