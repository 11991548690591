<!-- Need to experiment with view of list -->
<!-- <mat-form-field appearance="fill">
    <mat-select
    (selectionChange)="onLanguageChange($event.value)"
    value="selected">

        <mat-option *ngFor="let language of languages">
        {{ language }}
        </mat-option>
    </mat-select>
</mat-form-field> -->

<!-- <mat-form-field class="drop-main" appearance="fill">
    <mat-select [value]="selected"
        (selectionChange)="onLanguageChange($event.value)">
        <mat-select-trigger class="trigger">{{ selected }}</mat-select-trigger>
        <mat-option *ngFor="let language of languages" [value]="language">
            {{ language }}
        </mat-option>
    </mat-select>
</mat-form-field> -->

<div>
    <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon aria-hidden="false">language</mat-icon>
        {{ SelectedLanguage }}
    </button>
    <mat-menu #menu="matMenu">
        <mat-option *ngFor="let language of languages">
            <span mat-menu-item (click)="onLanguageChange(language)">{{ language }}</span>
        </mat-option>
    </mat-menu>
</div>