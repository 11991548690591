import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { UserInfoModule } from './user-info/user-info.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { LeftSideMenuComponent } from './left-side-menu/left-side-menu.component';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouteConfigModule } from '../routes-config/route-config.module';
import { SpinnerModule } from 'src/app/shared/components/spinner/spinner.module';
import { SvgImageModule } from 'src/app/shared/components/svg-image/svg-image.module';
import {MatTabsModule} from '@angular/material/tabs';
import { RouteModalModule } from 'src/app/shared/components/route-modal/route-modal.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchInputModule } from 'src/app/shared/components/inputs/search-input/search-input.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { MatMenuModule } from '@angular/material/menu';
import { PopupModule } from 'src/app/shared/components/popup/popup.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { AuthModule } from '../auth/auth.module';
import { NotificationModalModule } from 'src/app/shared/components/notification-modal/notification-modal.module';
import { DeleteModalService } from 'src/app/shared/components/notification-modal/delete-modal/delete-modal.service';
import { SharedPipesModule } from 'src/app/shared/pipes/shared-pipes.module';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';
import { PwaModule } from 'src/app/shared/pwa/pwa.module';
import { SharedDirectivesModule } from 'src/app/shared/directives/shared-directives.module';


@NgModule({
  declarations: [
    MainComponent,
    LeftSideMenuComponent,
    GlobalSearchComponent,
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SpinnerModule,
    MatSidenavModule,
    UserInfoModule,
    ToolbarModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    SvgImageModule,
    RouteConfigModule,
    MatTabsModule,
    RouteModalModule,
    ScrollingModule,
    SearchInputModule,
    FlexLayoutModule,
    PopupModule,
    AuthModule,
    NotificationModalModule,
    FooterModule,
    PwaModule
  ],
  exports: [
    MainComponent
  ],
  providers: [
    DeleteModalService
  ]
})
export class MainModule { }
