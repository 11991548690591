import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserModelBasic } from 'src/app/core/services/users/users.typings';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { UserAuthMessage, UserLogoutMessage } from 'src/app/core/authentication/auth.typing';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ToolbarComponent implements OnDestroy {
  @Input() sidenav: MatSidenav;
  @Output() onMenuClick = new EventEmitter();
   
  constructor(
    protected messageBus: MessageBusService,
    public readonly cd: ChangeDetectorRef,
    protected readonly router: Router) {
     
  }

  toggleMenu() {
    this.onMenuClick.emit();
  }

  toMain() {
    this.router.navigate(['/animals']);
  }

  ngOnDestroy(): void {
  }
}
