<div class="relative">
    <div class="label"><label ellipsifyText>{{ placeholder | translate }}</label> <span *ngIf='isRequired()' class='star'>*</span></div>
    <mat-form-field
        appearance="outline">
        <mat-date-range-input
            #dateRangeInput
            [formGroup]="rangeGroup"
            [rangePicker]="picker"
            [comparisonStart]="rangeGroup.value.start"
            [comparisonEnd]="rangeGroup.value.end"
            [matTooltip]="placeholder | translate"
            [min]="minDate"
            [max]="maxDate">
            <input
                #startDate
                class="date-input start-date"
                matStartDate
                placeholder="Start date"
                formControlName="start"
                >
            <input
                #endDate
                class="date-input"
                matEndDate
                formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"
                               class="date-picker-toggle">
            <svg-img
                class="toogle-icon"
                [class.focused]="dateRangeInput.focused"
                [class.disable-toogle-icon]="vm.disabled"
                [class.invalid]="vm.invalid && vm.touched"
                matDatepickerToggleIcon name="calendar"></svg-img>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</div>

