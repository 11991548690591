import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

const global: any = window;

@Component({
  selector: 'svg-img',
  templateUrl: './svg-image.component.html',
  styleUrls: ['./svg-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgImageComponent implements OnInit {
  @Input() name: string;
  svgImage: string;

  constructor() {}

  ngOnInit() {
    this.svgImage = global.svgStorage && global.svgStorage[this.name];
  }

}
