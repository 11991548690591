import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-validations-container',
  templateUrl: './validations-container.component.html',
  styleUrls: ['./validations-container.component.scss'],
})
export class ValidationsContainerComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() disabled: boolean;

  constructor(private readonly cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.item) {
      this.item.statusChanges.pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.cd.detectChanges();
      });
    }
  }

  get item(): UntypedFormControl {
    return this.control;
  }

  get serverErrMessage() {
    return this.item && this.item.errors && this.item.errors.serverErrMessage;
  }

  public isServerMessage = () => {
    return this.serverErrMessage && this.serverErrMessage.length > 0;
  }

  ngOnDestroy() {}

}
