import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PwaOfflineService } from '../services/pwa-offline-service';

@UntilDestroy()
@Component({
  selector: 'app-offline-notification',
  templateUrl: './offline-notification.component.html',
  styleUrls: ['./offline-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineNotificationComponent implements OnInit {
  public show = false;
  
  constructor(
      protected pwaOfflineService: PwaOfflineService,
      protected readonly cd: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
    this.pwaOfflineService.isOffline$
    .pipe(untilDestroyed(this))
    .subscribe((value) => {
      this.show = value;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {

  }

}
