import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { BasePage } from 'src/app/core/base-pages/base-page';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { Location } from '@angular/common';
import { BaseViewModel } from 'src/app/core/base-viewmodel/base-viewmodel';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { createValidatorsArray } from 'src/app/shared/validators/validators.mapper';
import { untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'src/app/core/helpers/omit';
import { ShowInputsErrorMessage } from 'src/app/shared/components/inputs/inputs.typings';



@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent extends BasePage implements OnInit {
  public showRegistration = true;

  public isNewFarm = new UntypedFormControl(true);

  public farmViewModel = new UntypedFormGroup(
    {
      name: new UntypedFormControl('', createValidatorsArray([
        'required',
        {
          name: 'maxLength',
          value: 50
        }
      ])),
    }
  );

  public viewModel = new BaseViewModel(
    {
      username: new UntypedFormControl('', createValidatorsArray([
        'required',
        {
          name: 'maxLength',
          value: 50
        }
      ])),
      email: new UntypedFormControl('', createValidatorsArray([
        'required',
        'email',
      ])),
      password: new UntypedFormControl('', createValidatorsArray([
        'required',
        'simplePassword'
      ])),
      repeatPassword: new UntypedFormControl(''),
      farm_id: new UntypedFormControl(null, createValidatorsArray([
        'required',
      ])),
      farmData: this.farmViewModel
    }
  );
  
  constructor(
    protected readonly router: Router,
    protected readonly authService: AuthenticationService,
    protected readonly messageBus: MessageBusService,
    protected location: Location,
    protected readonly cd: ChangeDetectorRef,
) {
    super(router, authService, messageBus, location, cd);
}

  ngOnInit(): void {
    this.viewModel.get('repeatPassword').setValidators([Validators.required, this.matchFieldValidator('password')]);
    this.viewModel.get('farm_id').disable();

    this.isNewFarm.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((value) => {
      if(value) {
        this.viewModel.get('farm_id').disable();
        this.farmViewModel.enable();
      } else {
        this.viewModel.get('farm_id').enable();
        this.farmViewModel.disable();
      }

    }) 

  }

  onSubmit() {
    this.viewModel.markAllAsTouched();
    this.viewModel.updateValueAndValidity();

    if(this.viewModel.valid) {
      this.isLoad = true;
      this.authService.registration(omit({
        ...this.viewModel.value,
        password: this.viewModel.value.password?.trim(),
        farm_id: this.viewModel.value.farm_id?.id
      }, ['id', 'version', 'repeatPassword']))
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.isLoad = false;
          this.showRegistration = false;
        },
        () => {
          this.isLoad = false;          
          // this.showError = true;
          //setTimeout(() => {this.showError = false}, 4000);
        })      
    } else {
      this.messageBus.publish<ShowInputsErrorMessage>(new ShowInputsErrorMessage());
    }

  }

  public resendEmail(): void {

  }

  // Custom password confirmation validation
  protected matchFieldValidator(fieldToMatch:string) : ValidatorFn {
    return (control : AbstractControl) : { [key: string]: any;} => {
      let confirmField = this.viewModel.get(fieldToMatch);

      return (confirmField && control.value !== confirmField.value) ? {match:false} : null;
    }
  }
  

}
