import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicOfferComponent } from './public-offer/public-offer.component';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    PublicOfferComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule    
  ],
  exports: [
    PublicOfferComponent
  ]
})
export class PublicOfferModule { }
