import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationsModule } from '../../validations/validations.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SvgImageModule } from '../../svg-image/svg-image.module';
import { DateRangeComponent } from './daterange.component';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [
    CommonModule,
    ValidationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatMomentDateModule,
    SvgImageModule
  ],
  exports: [
    DateRangeComponent
  ]
})
export class DateRangeModule { }