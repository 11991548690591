import { AppTranslateManagerService } from './../../../core/translate/services/app-translate-manager.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'language-switcher',
  templateUrl: './languageswitcher.component.html',
  styleUrls: ['./languageswitcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  public selected: string = 'en';
  public languages = environment.locales.map((item: string) => item.toUpperCase());

  constructor(private readonly translateService: AppTranslateManagerService) { }

  ngOnInit(): void {
    this.selected = this.translateService.getCurrentLang();
  }

  onLanguageChange(language: string) {
    this.selected = language;
    this.translateService.changeLang(language.toLowerCase());
  }

  public get SelectedLanguage(): string {
    return this.selected.toUpperCase()
  }

}
