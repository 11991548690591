import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from 'src/app/modules/main/toolbar/toolbar.component';
import { UserInfoModule } from '../user-info/user-info.module';
import { NotificationHeaderModule } from '../notification-header/notification-header.module';
import { MatButtonModule } from '@angular/material/button';

import { TranslateSelectComponent } from 'src/app/modules/main/toolbar/translate-select/translate-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { SvgImageModule } from 'src/app/shared/components/svg-image/svg-image.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthModule } from '../../auth/auth.module';
import { ProfilePanelComponent } from '../profile-panel/profile-panel.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { LanguageSwitcherModule } from 'src/app/shared/components/languageswitcher/languageswitcher.module';
import { SharedDirectivesModule } from 'src/app/shared/directives/shared-directives.module';
import { PdfExportModule } from "../../../shared/components/pdf-export/pdf-export.module";
import { DateRangeModule } from 'src/app/shared/components/inputs/daterange/daterange.module';


@NgModule({
    declarations: [
        ToolbarComponent,
        TranslateSelectComponent,
        ProfilePanelComponent
    ],
    exports: [
        ToolbarComponent,
        TranslateSelectComponent,
        ProfilePanelComponent,
    ],
    imports: [
        CommonModule,
        UserInfoModule,
        SharedDirectivesModule,
        NotificationHeaderModule,
        MatButtonModule,
        SvgImageModule,
        TranslateModule,
        MatSelectModule,
        MatIconModule,
        AuthModule,
        FlexLayoutModule,
        RouterModule,
        LanguageSwitcherModule,
        DateRangeModule
        //HelpViewerModule
        ,
        PdfExportModule
    ]
})
export class ToolbarModule {
}
