import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: `./checkbox.component.html`,
  styleUrls: [`./checkbox.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() vm: UntypedFormControl = new UntypedFormControl({});
  @Output() public onChange = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) { }

 
}
