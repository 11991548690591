import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { NAVIGATION_MODULES } from './router-link.config';
import { NavItem, NavModule } from '../main/left-side-menu/left-side-menu.typing';

@Injectable({
  providedIn: 'root'
})
export class RouteConfigService {

  private modulesRoutes$ = new BehaviorSubject(NAVIGATION_MODULES);

  constructor(/*private readonly permissionService: ItaPermissionService,*/
              private messageBusService: MessageBusService) {
    //this.messageBusService.sync(UploadPrivilegesMessage).subscribe(this.filterRoutes);
    this.filterRoutes();
  }

  private filterRoutes = () => {
    const newRoutes: Record<string, NavItem[]> = {};
    Object.entries(NAVIGATION_MODULES).forEach(([moduleId, moduleItems]) => {
      newRoutes[moduleId] = this.filterNavItems(moduleItems);
    });
    this.modulesRoutes$.next(newRoutes);
  }

  getModuleRoutes(moduleId: string): Readonly<NavItem[]> {
    return this.modulesRoutes$.value[moduleId];
  }

  get modulesRoutes(): Observable<NavModule> {
    return this.modulesRoutes$.asObservable();
  }

  private filterNavItems(items: NavItem[]): NavItem[] {
    return items.filter(navItem => {
      if (navItem.children) {
        navItem.children = this.filterNavItems(navItem.children);
        return navItem.children.length > 0;
      }
      return navItem.perms.every(permission => /* this.permissionService.hasPrivilege(permission)*/ true);
    });
  }

  resolveRoute(url: string): NavItem | null {
    const [, moduleId, ...rest] = url.split('/');
    const moduleRoutes = this.getModuleRoutes(moduleId);
    if (!moduleRoutes) {
      return null;
    }
    const route = this.findRoute(moduleRoutes, url);
    if (!route) {
      return this.findFirstChild(moduleRoutes[0]);
    }
    return this.findFirstChild(route);
  }

  private findRoute(routes: Readonly<NavItem[]>, url: string): NavItem | null {
    for (const route of routes) {
      if (route.url === url) {
        return route;
      }
      if (route.children) {
        const childRoute = this.findRoute(route.children, url);
        if (childRoute) {
          return childRoute;
        }
      }
    }

    return null;
  }

  private findFirstChild(route: Readonly<NavItem>): NavItem {
    if (route?.children) {
      return this.findFirstChild(route.children[0]);
    }
    return route;
  }
}
