import { Injectable } from '@angular/core';
import { DataProvider } from 'src/app/core/data-provider/data-provider';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { BirthsApiService } from './births-api.service';
import { BirthDto } from './births.typings';


@Injectable({providedIn: 'root'})
export class BirthsProvider extends DataProvider<BirthDto> {
    constructor(protected api: BirthsApiService, protected messageBus: MessageBusService) {
        super(api, messageBus);
    }

}
