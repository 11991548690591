import { NavItem, NavModule } from '../main/left-side-menu/left-side-menu.typing';

export const ADMINISTRATION_NAV_ITEMS: NavItem[] = [
  {
    id: 'users',
    title: 'Список пользователей',
    url: '/users',
    icon: '',
    perms: []
  },

];

export const USER_PROFILE_NAV_ITEMS: NavItem[] = [
  {
    id: 'general-info',
    title: 'Профиль',
    url: 'user-profile/general-info',
    icon: '',
    perms: []
  },
  {
    id: 'scheduler',
    title: 'График работы',
    url: 'user-profile/scheduler',
    icon: '',
    perms: []
  },
];

export const SERVICES_NAV_ITEMS: NavItem[] = [
  {
    id: 'massage',
    title: 'Массаж',
    url: 'services/massage',
    icon: '',
    perms: []
  },
  {
    id: 'bathhouse',
    title: 'Банный комплекс',
    url: 'services/bathhouse',
    icon: '',
    perms: []
  },
];

export const CLIENTS_NAV_ITEMS: NavItem[] = [
  {
    id: 'clients',
    title: 'Список клиентов',
    url: '/clients',
    icon: '',
    perms: []
  },

  /*{
    id: 'document_control',
    title: 'app.explotation.left-menu.document_control.title',
    icon: 'document_validity_control',
    url: '/explotation/document_control',
    perms: [],
    children: [
      {
        id: 'drivers',
        title: 'app.explotation.left-menu.document_control.driver',
        url: '/explotation/document_control/drivers',
        icon: 'driver-document-control',
        perms: ['DRIVER_READ', 'DOCUMENT_READ'],
      },
    ],
  } */
];


export const NAVIGATION_MODULES: NavModule = {
  administration: ADMINISTRATION_NAV_ITEMS,
  clients: CLIENTS_NAV_ITEMS,
  'user-profile': USER_PROFILE_NAV_ITEMS,
  services: SERVICES_NAV_ITEMS
};

export const dictionaryIcons: any = [
  {
    name: 'clients',
    title: 'app.clients.title',
    route: null
  },

];

