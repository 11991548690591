import { AbstractControl } from '@angular/forms';
import {
  lengthReg,
  latinDigits,
  latinDigitsAndMinus,
  minLength,
  maxLength,
  min,
  max,
  required,
  latin,
  cyrillic,
  digits,
  cyrillicAndHyphen,
  latinAndHyphen,
  latinAndCyrillicAndDigits,
  latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen,
  email,
  maxLengthWith2SignsAfterComma,
  maxLengthDigit,
  minLengthDigit,
  positiveDigits,
  latinAndDigitsAndSpecialSymbolAndHyphenAndDot,
  simplePassword,
  latinAndDigitsAndSpecialSymbol,
  positiveCommaDigits,
  digitsAndHyphen,
  digitsAndSlash,
  validArray,
  validEmails,
  notSpaceBegin,
  latinAndCyrillicAndDigitsAndHyphen,
  cyrillicAndHyphenAndSpaceAndApostrophe,
  latinAndHyphenAndSpaceAndApostrophe,
  digitsAndSlashAndHyphen,
  latinAndCyrillicAndDigitsAndSpaceAndSpec,
  upperLatinCyrillicAndDigits,
  positiveCommaDigitsWithLimit,
  cyrillicAndHyphenAndSpaceAndApostropheAndDot,
  latinAndHyphenAndSpaceAndApostropheAndDot,
  latinAndUnderscore,
  url,
  dateInTheFuture,
  dateInThePast,
  dateLeastYear,
  dateMoreYear
} from './validators';

export interface RuleModel {
  name: string;
  value: any;
}

export const validatorsMapper = {
    required: (control: AbstractControl) => required(control),
    latinDigits: (control: AbstractControl) => latinDigits(control),
    latinDigitsAndMinus: (control: AbstractControl) => latinDigitsAndMinus(control),
    latinAndDigitsAndSpecialSymbolAndHyphenAndDot: (control: AbstractControl) => latinAndDigitsAndSpecialSymbolAndHyphenAndDot(control),
    latin: (control: AbstractControl) => latin(control),
    latinAndHyphen: (control: AbstractControl) => latinAndHyphen(control),
    cyrillic: (control: AbstractControl) => cyrillic(control),
    cyrillicAndHyphen: (control: AbstractControl) => cyrillicAndHyphen(control),
    digits: (control: AbstractControl) => digits(control),
    positiveCommaDigits: (control: AbstractControl) => positiveCommaDigits(control),
    positiveDigits: (control: AbstractControl) => positiveDigits(control),
    latinAndCyrillicAndDigits: (control: AbstractControl) => latinAndCyrillicAndDigits(control),
    latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen: (control: AbstractControl) =>
      latinAndCyrillicAndDigitsAndSpecialSymbolAndHyphen(control),
    min: (value) => min(value),
    max: (value) => max(value),
    minLength: (value) => minLength(value),
    maxLength: (value) => maxLength(value),
    minLengthDigit: (value) => minLengthDigit(value),
    maxLengthDigit: (value) => maxLengthDigit(value),
    length: (value) => lengthReg(value),
    email: (control: AbstractControl) => email(control),
    dateInTheFuture: (control) => dateInTheFuture(control),
    dateInThePast: (control) => dateInThePast(control),
    dateLeastYear: (value) => dateLeastYear(value),
    dateMoreYear: (value) => dateMoreYear(value),
    url: (control: AbstractControl) => url(control),
    maxLengthWith2SignsAfterComma: (control) => maxLengthWith2SignsAfterComma(control),
    simplePassword: (control: AbstractControl) => simplePassword(control),
    latinAndDigitsAndSpecialSymbol: (control: AbstractControl) => latinAndDigitsAndSpecialSymbol(control),
    digitsAndHyphen: (control: AbstractControl) => digitsAndHyphen(control),
    digitsAndSlash: (control: AbstractControl) => digitsAndSlash(control),
    validArray: (value) => validArray(value),
    validEmails: (control: AbstractControl) => validEmails(control),
    notSpaceBegin: (control: AbstractControl) => notSpaceBegin(control),
    latinAndCyrillicAndDigitsAndHyphen: (control: AbstractControl) => latinAndCyrillicAndDigitsAndHyphen(control),
    cyrillicAndHyphenAndSpaceAndApostrophe: (control: AbstractControl) => cyrillicAndHyphenAndSpaceAndApostrophe(control),
    latinAndHyphenAndSpaceAndApostrophe: (control: AbstractControl) => latinAndHyphenAndSpaceAndApostrophe(control),
    digitsAndSlashAndHyphen: (control: AbstractControl) => digitsAndSlashAndHyphen(control),
    latinAndCyrillicAndDigitsAndSpaceAndSpec: (control: AbstractControl) => latinAndCyrillicAndDigitsAndSpaceAndSpec(control),
    upperLatinCyrillicAndDigits: (control: AbstractControl) => upperLatinCyrillicAndDigits(control),
    positiveCommaDigitsWithLimit: (value) => positiveCommaDigitsWithLimit(value),
    cyrillicAndHyphenAndSpaceAndApostropheAndDot: (control: AbstractControl) => cyrillicAndHyphenAndSpaceAndApostropheAndDot(control),
    latinAndHyphenAndSpaceAndApostropheAndDot: (control: AbstractControl) => latinAndHyphenAndSpaceAndApostropheAndDot(control),
    latinAndUnderscore: (control: AbstractControl) => latinAndUnderscore(control)
};

export const createValidatorsArray = (rules: any[]) => {
  if (!rules) {
    return [];
  }
  const validations = [];
  rules.forEach((rule: RuleModel | string) => {
    if (typeof rule === 'object') {
      if (validatorsMapper[rule.name] && (rule.value || rule.value === 0)) {
        validations.push(validatorsMapper[rule.name](rule.value));
      }
    } else {
      if (validatorsMapper[rule]) {
        validations.push(validatorsMapper[rule]);
      }
    }
  });
  return validations;
};

export const createControl = (el, newForm: any) => {
  const value = el.defaultValue ? el.defaultValue : '';
  if (el.validation) {
    newForm[el.name] = [
      value,
      {
        updateOn: 'blur',
        validators: createValidatorsArray(el.validation)
      }
    ];
  } else {
    newForm[el.name] = [value];
  }
};

function convertValidator(value: string): string {
  switch (value) {
    case 'maxValue':
      return 'max';
    case 'minValue':
      return 'min';
    case 'precision':
      return 'positiveCommaDigitsWithLimit';
    default:
      return value;
  }
}

export function addValidators(attribute): any[] {
  let validators = [];
  if (attribute.validation) {
    try {
      for (const property in attribute.validation) {
        if (property) {
          validators.push(
            {
              name: convertValidator(property),
              value: attribute.validation[property]
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
      validators = [];
    }
  }
  if (attribute.required) {
    validators.push('required');
  }
  return validators;
}

export default {
  createValidatorsArray,
  createControl,
  addValidators,
  validatorsMapper
};
