import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightPipe } from './highlight.pipe';
import { PersonNamePipe } from './person-name/person-name.pipe';
import { DateLocalizePipe } from './dates/date.localize.pipe';
import { DateTimeLocalizePipe } from './dates/date-time.localize.pipe';
import { DollarPricePipe } from './price/dollar-price.localize.pipe';
import { PercentagePricePipe } from './price/percentage-price..pipe';
import { DateAgoPipe } from './dates/date-ago.pipe';
import { UnitValuePipe } from './units/unit-value.localize.pipe';
import { BtcPricePipe } from './price/btc-price.localize.pipe';


@NgModule({
  declarations: [
    HighlightPipe,
    PersonNamePipe,
    DateLocalizePipe,
    DateTimeLocalizePipe,
    DollarPricePipe,
    PercentagePricePipe,
    DateAgoPipe,
    UnitValuePipe,
    BtcPricePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HighlightPipe,
    PersonNamePipe,
    DateLocalizePipe,
    DateTimeLocalizePipe,
    DollarPricePipe,
    PercentagePricePipe,
    DateAgoPipe,
    UnitValuePipe,
    BtcPricePipe,
  ],
})
export class SharedPipesModule { }
