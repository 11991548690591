import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { BasePage } from 'src/app/core/base-pages/base-page';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { Location } from '@angular/common';
import { BaseViewModel } from 'src/app/core/base-viewmodel/base-viewmodel';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { createValidatorsArray } from 'src/app/shared/validators/validators.mapper';
import { untilDestroyed } from '@ngneat/until-destroy';
import { omit } from 'src/app/core/helpers/omit';
import { ShowInputsErrorMessage } from 'src/app/shared/components/inputs/inputs.typings';



@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent extends BasePage implements OnInit {
  public viewModel = new BaseViewModel(
    {
      password: new UntypedFormControl('', createValidatorsArray([
        'required',
        'simplePassword'
      ])),
      passwordConfirmation: new UntypedFormControl(''),
    }
  );
  
  constructor(
    protected readonly router: Router,
    private route: ActivatedRoute,
    protected readonly authService: AuthenticationService,
    protected readonly messageBus: MessageBusService,
    protected location: Location,
    protected readonly cd: ChangeDetectorRef,
) {
    super(router, authService, messageBus, location, cd);
}

  ngOnInit(): void {
    this.viewModel.get('passwordConfirmation').setValidators([Validators.required, this.matchFieldValidator('password')]);
  }

  onSubmit() {
    this.viewModel.markAllAsTouched();
    this.viewModel.updateValueAndValidity();
    
    let code = this.route.snapshot.queryParams['code'];
    if(this.viewModel.valid) {
      this.isLoad = true;
      this.authService.resetPassword({
        password: this.viewModel.value.password,
        passwordConfirmation: this.viewModel.value.passwordConfirmation,
        code
      })
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.isLoad = false;
          this.router.navigate([`animals/list`]);
        },
        () => {
          this.isLoad = false;          
        })      
    } else {
      this.messageBus.publish<ShowInputsErrorMessage>(new ShowInputsErrorMessage());
    }

  }

  // Custom password confirmation validation
  protected matchFieldValidator(fieldToMatch:string) : ValidatorFn {
    return (control : AbstractControl) : { [key: string]: any;} => {
      let confirmField = this.viewModel.get(fieldToMatch);

      return (confirmField && control.value !== confirmField.value) ? {match:false} : null;
    }
  }
  

}
