import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from 'src/app/core/api-client/api-client';
import { environment } from 'src/environments/environment';
import { UserModelBasic, UserProfileDto } from './users.typings';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class UserApiService extends ApiClient<UserModelBasic> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, `${environment.host}users`);
  }

  public getMyProfile(): Observable<UserProfileDto> {
    return this.httpClient.get<UserProfileDto>(`${this.baseUrl}/me/profile`);
  }

  public updateMyProfile(data: Partial<UserProfileDto>, file = null, isFileUpdate = false): Observable<UserProfileDto> {
    
    if(file) {
      const formData = new FormData();
      formData.append('image', file, `${file.name}.png`);
      formData.append('username', data.username);

      return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/me/profile`, formData);
    }

    return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/me/profile`, 
      isFileUpdate ? { ...data, image: file } : data);
  }

  public deleteAvatar(): Observable<UserProfileDto> {    
    return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/me/profile`, {
      image: null
    });
  }

  public getCurrentUserDetails(): Observable<UserModelBasic> {
    return this.httpClient.get<UserModelBasic>(`${this.baseUrl}/auth`);
  }
}
