import { NgModule } from '@angular/core';
import { GlobalErrorHandlerComponent } from 'src/app/core/errors-handler/components/global-error-handler/global-error-handler.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorHandlerComponent } from 'src/app/core/errors-handler/components/form-error-handler/form-error-handler.component';

@NgModule({
    imports: [
        TranslateModule,
    ],
    declarations: [
        GlobalErrorHandlerComponent,
        FormErrorHandlerComponent,
    ],
    exports: [
        GlobalErrorHandlerComponent,
        FormErrorHandlerComponent,
    ]
})
export class ErrorsHandlerModule {

}
