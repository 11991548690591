<div class="input-container">
    <input
        matInput
        type="text"
        class="input"
        [name]="autocompleteName"
        placeholder="{{ 'app.main-animals.search' | translate }}"
        [attr.autocomplete]="autocompleteName" 
        [formControl]="vm" />
    <mat-icon>search</mat-icon>
</div>