import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {  BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { EntityId } from 'src/app/core/api-client/api-client.typings';
import { MessageBusService } from 'src/app/core/message-bus/message-bus-service';
import { FarmsProvider } from 'src/app/core/services/farms/farms.provider';
import { FarmDto } from 'src/app/core/services/farms/farms.typings';
import { isRequiredField } from 'src/app/shared/validators/validators';
import { ShowInputsErrorMessage } from '../inputs.typings';


export interface CompleteItem {
  id: number | string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'app-farms-autocomplete',
  templateUrl: './farms-autocomplete.component.html',
  styleUrls: ['./farms-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FarmsAutocompleteComponent implements OnInit, OnChanges, OnDestroy {

  @Input() vm: AbstractControl;
  @Input() label;
  @Input() placeholder;
  @Input() disabled = false;

  @Input() showErrorAfterSubmit = false;

  public showErrors: boolean = false;

  innerControl = new UntypedFormControl(null);
  
  public dictList: FarmDto[] = [];

  constructor(
    private readonly api: FarmsProvider,
    protected readonly messageBus: MessageBusService,
    private readonly cd: ChangeDetectorRef
  ) { }

  public get inputPlaceholder(): string {
      return this.placeholder;
  }

  public get needShowErrors(): boolean {
    return !this.showErrorAfterSubmit || this.showErrors;
  }

  ngOnInit() {

    if (this.vm) {
      this.vm.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.innerControl.setValue(this.getInputValue(value));
        });

      this.innerControl.setValidators(this.vm.validator);

      this.vm.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(status => {
          this.vm.enabled
            ? this.innerControl.enable()
            : this.innerControl.disable();
          
          this.innerControl.clearValidators();
          if (this.vm.validator) {
            this.innerControl.setValidators(this.vm.validator);
          }

          if(this.vm.pristine) {
            this.innerControl.markAsPristine();
            this.innerControl.markAsUntouched();
          } else {
            this.innerControl.markAllAsTouched();
            this.innerControl.setErrors(this.vm.errors);
          }

          this.cd.detectChanges();
        });

      this.innerControl.valueChanges
        .pipe(
          debounceTime(500),
          untilDestroyed(this)
        )
        .subscribe(value => {
          this.onBlur();
        });

    }

    this.innerControl.valueChanges
      .pipe(startWith(null))
      .pipe(
          debounceTime(500),
          switchMap((searchValue) => {
            return this.api.getList({
              populate: '*',
              'pagination[page]': '1',
              'pagination[pageSize]': `3000`,
            });
          }),
          untilDestroyed(this)
        )     
          .pipe(
            map((farms) => {
              return farms.data.map(item => {
                return {
                  id: item.id,
                  value: item.name,
                  ...item
                }
              })
            }))
            .subscribe(list => {
              this.dictList = list;
            });
    
    if (this.vm && this.vm.value) {
      this.innerControl.setValue(this.getInputValue(this.vm.value));
    }

    this.messageBus.of<ShowInputsErrorMessage>(ShowInputsErrorMessage)      
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((message: ShowInputsErrorMessage) => {
        this.innerControl.markAsTouched();
        this.innerControl.updateValueAndValidity();
        this.showErrors = true;
        this.cd.detectChanges();
        setTimeout(() => {
          this.showErrors = false;
          this.cd.detectChanges();
        }, message.time);
      });
  }

  public getImage(item): string {
    return item?.logo;
  }

  public onBlur(): void {
    const value = this.getSelectedItem(this.innerControl.value);
    
    if(value) {
      this.selectValue(value);
    }
    
  }

  private getInputValue(data: any): any {
    return data?.name || '';
  }

  private getSelectedItem(value: string): any {
    if(value) {
       const item = this.dictList.find(item => item.name === value);
       return item
    } else {
      return null;
    }   
  }

  public selectValue(item: CompleteItem) {
    if (!!item && item.id && (this.vm.value?.id !== (item && item.id))) {
      this.vm.markAsDirty();
      this.vm.setValue(item ? item : null);
    }
  }

  public isRequired(): boolean {
    return isRequiredField(this.vm);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && changes.disabled.currentValue != null && this.vm) {
      if (changes.disabled.currentValue) {
        this.vm.disable();
        this.innerControl.disable();
      } else {
        this.vm.enable();
        this.innerControl.enable();
      }
    }
  }

  clear() {
     this.innerControl.setValue(null);
  }

  ngOnDestroy() {
  }
}
