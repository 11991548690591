import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationHeaderComponent } from './notification-header.component';
import { MatIconModule } from '@angular/material/icon';
import { SystemNotificationsModule } from 'src/app/shared/components/system-notifications/system-notifications.module';
import { PopupModule } from 'src/app/shared/components/popup/popup.module';



@NgModule({
  declarations: [NotificationHeaderComponent],
  imports: [
    CommonModule,
    MatIconModule,
    SystemNotificationsModule,
    PopupModule
  ],
  exports: [NotificationHeaderComponent]
})
export class NotificationHeaderModule { }
